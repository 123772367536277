import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INTERCOM_APP_ID } from '../../../main/env';
import { AuthTokenMfaCreateDto, IntercomState } from '../../../shared';
import { PartnerMember } from '../../../shared/models';
import { AuthTokenTokenMfaCreate } from '../../../shared/models/AuthModel';
import { AuthTokenCreateResponse } from '../../../shared/models/AuthToken';
import { Partner } from '../../../shared/models/PartnerModel';
import { LoanPresetDto } from '../../../shared/models/platform-admin/loans/LoanPresetDto';
import { isAdmin, isAdminRole } from '../utils/index';

import {
  getIntercomInfo,
  acceptInvitation,
  authWithSecret,
  createAnonim,
  getPartner,
  getSelf,
  startLogin,
  forgotPasswordComplete,
  forgotPasswordStart,
} from './actions';
import { completeOnboarding } from './actions/completeOnboarding';
import { getChecklist } from './actions/getChecklist';
import { refreshToken } from './actions/refreshToken';
import { selectChecklistFlow } from './actions/selectChecklistFlow';

export type SelectedFlow = 'billpay' | 'affiliate';

export enum AssetType {
  ZIRTUE_PAY_BUTTON = 'Zirtue Pay Button',
  QUICK_LINK = 'Quick Link',
  BILL_PAY_POPUP = 'Bill Pay Popup',
}

export type TAsset = {
  title: string;
  text: string;
  iconSrc: string;
  iconSrcInner: string;
  assetType: AssetType;
  isOpen: boolean;
};

export type ChecklistData = { [key: string]: '0' | '1' };

export const FlowTypes = {
  BILL_PAY: 'BILL_PAY',
  AFFILIATE_PARTNER: 'AFFILIATE_PARTNER',
  LOAN_CREATOR: 'LOAN_CREATOR',
  UNKNOWN: 'UNKNOWN',
} as const;

type AuthSliceState = {
  // Revisit to see if we can make it just string (remove undefined)
  email: string | undefined;
  forgotToken: string;
  token?: string | null;
  phone?: string;
  step?: number;
  auth: Partial<AuthTokenTokenMfaCreate>;
  authCreateDto?: AuthTokenMfaCreateDto;
  authToken?: AuthTokenCreateResponse;
  loading: boolean;
  isAuthenticated: boolean;
  user?: PartnerMember;
  partner?: Partner;
  campaign?: LoanPresetDto;
  assets: TAsset[];
  selectedAsset: TAsset | null;
  intercom: IntercomState;
  isAdmin: boolean;
  selectedFlow:
    | typeof FlowTypes.UNKNOWN
    | typeof FlowTypes.BILL_PAY
    | typeof FlowTypes.AFFILIATE_PARTNER
    | typeof FlowTypes.LOAN_CREATOR;
  currentStepSetup: number;
  finishedSetup: boolean;
  isPartnerLoading: boolean;
  toggleGetHelpPanel: boolean;
  checklist: ChecklistData;
  checklistFinished: boolean;
};

const authSliceStateAssets: TAsset[] = [
  {
    title: 'Zirtue Pay Button',
    text: 'Integrate the Zirtue Pay Button',
    iconSrc: 'images/dashboard/code-solid.svg',
    iconSrcInner: 'images/dashboard/code-solid-mint.svg',
    assetType: AssetType.ZIRTUE_PAY_BUTTON,
    isOpen: true,
  },
  {
    title: 'Quick Link',
    text: 'A shareable URL solution',
    iconSrc: 'images/dashboard/link-solid.svg',
    iconSrcInner: 'images/dashboard/link-solid-mint.svg',
    assetType: AssetType.QUICK_LINK,
    isOpen: true,
  },
  {
    title: 'Bill Pay Popup',
    text: 'An embedded experience',
    iconSrc: 'images/dashboard/external-link-alt-solid.svg',
    iconSrcInner: 'images/dashboard/external-link-alt-solid-mint.svg',
    assetType: AssetType.BILL_PAY_POPUP,
    isOpen: true,
  },
];

const initialState: AuthSliceState = {
  email: '',
  forgotToken: '',
  token: localStorage.getItem('auth-token'),
  phone: '',
  step: 1,
  loading: false,
  auth: {},
  isAuthenticated: false,
  isAdmin: false,
  assets: authSliceStateAssets,
  selectedAsset: null,
  intercom: {
    appID: INTERCOM_APP_ID(),
  },
  selectedFlow: FlowTypes.UNKNOWN,
  currentStepSetup: 1,
  finishedSetup: false,
  isPartnerLoading: false,
  toggleGetHelpPanel: false,
  checklist: {},
  checklistFinished: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState as AuthSliceState,
  reducers: {
    setEmail: (state, action: PayloadAction<string | undefined>) => {
      state.email = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setMfaToken: (state, action: PayloadAction<string>) => {
      state.auth.mfaToken = action.payload;
    },
    setCurrentStepSetup: (state, action: PayloadAction<number>) => {
      state.currentStepSetup = action.payload;
    },
    setFinishedSetup: (state, action: PayloadAction<boolean>) => {
      state.finishedSetup = action.payload;
    },
    setToggleGetHelpPanel: (state, action: PayloadAction<boolean>) => {
      state.toggleGetHelpPanel = action.payload;
    },
    setChecklistFinished: (state, action: PayloadAction<boolean>) => {
      state.checklistFinished = action.payload;
    },
    setSelectedFlow: (
      state,
      action: PayloadAction<
        | typeof FlowTypes.UNKNOWN
        | typeof FlowTypes.BILL_PAY
        | typeof FlowTypes.AFFILIATE_PARTNER
        | typeof FlowTypes.LOAN_CREATOR
      >,
    ) => {
      state.selectedFlow = action.payload;
    },
    setSelectedCampaign: (state, action: PayloadAction<LoanPresetDto>) => {
      state.campaign = action.payload;
    },
    resetSelectedCampaign: (state) => {
      state.campaign = undefined;
    },
    setAssets: (state, action: PayloadAction<TAsset[]>) => {
      state.assets = action.payload;
    },
    setSelectedAsset: (state, action: PayloadAction<TAsset | null>) => {
      state.selectedAsset = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<AuthTokenCreateResponse>) => {
      state.authToken = action.payload;
      state.isAuthenticated = true;
      state.isAdmin = isAdminRole(action.payload) || isAdmin(action.payload);
    },
    resetAuthToken: (state) => {
      state.authToken = undefined;
      state.isAuthenticated = false;
      state.isAdmin = false;
    },
    resetUser: (state) => {
      state.user = undefined;
    },
    resetIntercom: (state) => {
      state.intercom = {
        appID: INTERCOM_APP_ID(),
      };
    },
    clearAuthState: (state) => {
      state.user = undefined;
      state.authToken = undefined;
      state.isAuthenticated = false;
      state.isAdmin = false;
      state.intercom = {
        appID: INTERCOM_APP_ID(),
      };
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startLogin.fulfilled, (state, action) => {
      state.auth = action.payload;
      state.loading = false;
    });
    builder.addCase(startLogin.pending, (state, action) => {
      state.loading = true;
      state.authCreateDto = action.meta.arg;
    });
    builder.addCase(startLogin.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createAnonim.fulfilled, (state, action) => {
      state.authToken = action.payload;
      state.isAdmin = isAdminRole(action.payload) || isAdmin(action.payload);
    });
    builder.addCase(authWithSecret.fulfilled, (state, action) => {
      state.authToken = action.payload;
      state.isAuthenticated = true;
      state.loading = false;
      state.isAdmin = isAdminRole(action.payload) || isAdmin(action.payload);
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.authToken = action.payload;
      state.isAdmin = isAdminRole(action.payload) || isAdmin(action.payload);
    });
    builder.addCase(acceptInvitation.fulfilled, (state, action) => {
      state.authToken = action.payload;
      state.isAuthenticated = true;
      state.loading = false;
      state.isAdmin = isAdminRole(action.payload) || isAdmin(action.payload);
    });
    builder.addCase(acceptInvitation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(acceptInvitation.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(completeOnboarding.fulfilled, (state, action) => {
      state.partner!.details.flowType = action.payload;
      state.loading = false;
    });
    builder.addCase(completeOnboarding.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(completeOnboarding.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(selectChecklistFlow.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(selectChecklistFlow.fulfilled, (state, action) => {
      state.checklist = action.payload;
      state.loading = false;
    });
    builder.addCase(selectChecklistFlow.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getChecklist.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getChecklist.fulfilled, (state, action) => {
      state.checklist = action.payload;
      const checklistFinished = Object.keys(action.payload).every(
        (key) => action.payload[key] === '1',
      );
      state.checklistFinished = checklistFinished;
      state.loading = false;
    });
    builder.addCase(getChecklist.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSelf.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(getPartner.fulfilled, (state, action) => {
      state.partner = action.payload;
      state.finishedSetup = action.payload.details.flowType !== 'UNKNOWN';
      state.isPartnerLoading = true;
    });
    builder.addCase(getPartner.rejected, (state) => {
      state.isPartnerLoading = false;
    });
    builder.addCase(getIntercomInfo.fulfilled, (state, action) => {
      state.intercom = { ...state.intercom, ...action.payload };
    });
    builder.addCase(forgotPasswordStart.fulfilled, (state, action) => {
      state.email = action.payload.email;
    });
    builder.addCase(forgotPasswordComplete.fulfilled, (state, action) => {
      state.authToken = action.payload;
      state.isAuthenticated = true;
      state.loading = false;
      state.isAdmin = isAdminRole(action.payload) || isAdmin(action.payload);
    });
  },
});

export default authSlice;
export const { reducer: authReducer } = authSlice;
export const {
  setEmail,
  setStep,
  setMfaToken,
  setAuthToken,
  resetAuthToken,
  setIsAuthenticated,
  resetIntercom,
  resetUser,
  clearAuthState,
  setSelectedFlow,
  setSelectedCampaign,
  resetSelectedCampaign,
  setAssets,
  setSelectedAsset,
  setCurrentStepSetup,
  setFinishedSetup,
  setToggleGetHelpPanel,
  setChecklistFinished,
} = authSlice.actions;
