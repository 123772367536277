import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { addDays, addYears } from 'date-fns';
import { uniq, without } from 'lodash';

import { DateRangeTimeUnits, DateRangeTuple, DateRangeType } from '../../../shared';
import { FundingSource } from '../../../shared/models/FundingSource';
import {
  LoanDto,
  UserDto,
  UserRoleString,
  UsersSearchDateRangeField,
  UsersOnboardingType,
  UsersVerificationStatus,
} from '../../../shared/models/platform-admin';
import {
  LoanPresetCreatedDto,
  LoanPresetDto,
  LoanPresetStateEnum,
} from '../../../shared/models/platform-admin/loans/LoanPresetDto';
import { AdminPartnerDto } from '../../../shared/models/platform-admin/partners';
import { TransferDto } from '../../../shared/models/platform-admin/transactions';
import { SortOrder } from '../../../shared/models/SortOrder';
import { DateRangeSettings } from '../../../shared/types';
import { getTimePeriod, getPageSettings, resetPageSettings } from '../../../shared/utils';

import {
  adminLoan,
  adminLoanPresetsList,
  adminLoansSearch,
  adminPartnersSearch,
  adminUserActiveControl,
  adminUsersSearch,
} from './actions';
import { adminLoanPresetActivate } from './actions/adminLoanPresetActivate';
import { adminLoanPresetById } from './actions/adminLoanPresetById';
import { adminLoanPresetCreate } from './actions/adminLoanPresetCreate';
import { adminLoanPresetUpdate } from './actions/adminLoanPresetUpdate';
import { adminTransaction } from './actions/adminTransaction';
import { adminTransactionsSearch } from './actions/adminTransactionsSearch';
import { getUserPaymentMethods } from './actions/getUserPaymentMethods';
import { loansAdapter, partnersAdapter, usersAdapter } from './entityAdapters';
import { transactionsAdapter } from './entityAdapters/transactionsAdapter';

interface PaginationState {
  page: number;
  pagesCount: number;
  pageSize: number;
  total: number;
}

interface TableState<TItem> {
  collection: EntityState<TItem>;
  isLoading: boolean;
  selectedItemId?: string;
  searchQuery: string;
  dateRangeFilter: DateRangeTuple;
  stage?: string;
  transferStatus?: string;
  isReset?: boolean;
  sortField?: string;
  sortOrder?: SortOrder;
}

interface UsersState extends PaginationState, TableState<UserDto> {
  rolesFilter?: UserRoleString[];
  dateRangeFilterField: UsersSearchDateRangeField;
  dateRangeSettings: DateRangeSettings;
  onboardType?: UsersOnboardingType;
  verificationStatus?: UsersVerificationStatus;
  selectedUserPaymentMethods: FundingSource[];
}

interface LoansState extends PaginationState, TableState<LoanDto> {
  dateRangeSettings: DateRangeSettings;
}

interface TransactionsState extends PaginationState, TableState<TransferDto> {
  dateRangeSettings: DateRangeSettings;
}

interface PartnersState extends PaginationState, TableState<AdminPartnerDto> {}

interface selectedItemLoanState extends PaginationState {
  collection: LoanDto[];
  isLoading?: boolean;
  selectedItemId?: string;
  searchQuery?: string;
  dateRangeFilter?: DateRangeTuple;
  stage?: string;
  transferStatus?: string;
  isReset?: boolean;
  sortField?: string;
  sortOrder?: SortOrder;
}

interface selectedItemTransactionState extends PaginationState {
  collection: TransferDto[];
  isLoading?: boolean;
  selectedItemId?: string;
  searchQuery?: string;
  dateRangeFilter?: DateRangeTuple;
  stage?: string;
  transferStatus?: string;
  isReset?: boolean;
  sortField?: string;
  sortOrder?: SortOrder;
}

interface AdminSliceState {
  users: UsersState;
  loans: LoansState;
  transactions: TransactionsState;
  partners: PartnersState;
  selectedItemLoan: selectedItemLoanState;
  selectedItemTransaction: selectedItemTransactionState;
  selectedDeeplinkPresets: LoanPresetDto[];
  selectedDeeplinkPreset: LoanPresetDto | null;
  selectedDeeplinkPresetLoading: boolean;
  createdDeeplinkPreset: LoanPresetCreatedDto;
}

export type AdminTableName =
  | 'users'
  | 'loans'
  | 'transactions'
  | 'partners'
  | 'selectedItemLoan'
  | 'selectedItemTransaction';

interface SetTablePageSizePayload {
  tableName: AdminTableName;
  size: number;
}

interface SetTablePagePayload {
  tableName: AdminTableName;
  page: number;
}

interface SelectTableItemPayload {
  tableName: AdminTableName;
  id: string;
}

interface SetTableSearchQueryPayload {
  tableName: AdminTableName;
  query: string;
}

interface SetTableSearchDateFilterPayload {
  tableName: AdminTableName;
  range: DateRangeTuple;
}

interface SetTableStageFilterPayload {
  tableName: AdminTableName;
  stage: string;
}

interface SetTableTransferStatusFilterPayload {
  tableName: AdminTableName;
  transferStatus: string;
}

interface SetTableSortOrderPayload {
  tableName: AdminTableName;
  sortOrder: SortOrder;
}

interface SetTableSortFieldPayload {
  tableName: AdminTableName;
  sortField: string;
}

interface SetTableDateRangeSettingsPayload {
  tableName: AdminTableName;
  dateRangeSettings: DateRangeSettings;
}

const getSavedDates = (name: AdminTableName): DateRangeTuple => {
  const startDate = name === 'users' ? addYears(new Date(), -1) : addDays(new Date(), -30);
  return [
    getPageSettings(name, 'dateStart') || startDate.toISOString(),
    getPageSettings(name, 'dateEnd') || new Date().toISOString(),
  ];
};

const initialState: AdminSliceState = {
  users: {
    collection: usersAdapter.getInitialState(),
    isLoading: false,
    page: getPageSettings('users', 'page') || 1,
    pageSize: getPageSettings('users', 'size') || 8,
    pagesCount: 1,
    total: 0,
    searchQuery: getPageSettings('users', 'query') || '',
    dateRangeFilter: getSavedDates('users'),
    dateRangeSettings: getPageSettings('users', 'dateRangeSettings') || {
      type: DateRangeType.relative,
      timeUnits: DateRangeTimeUnits.months,
      value: 12,
    },
    dateRangeFilterField:
      getPageSettings('users', 'dateRangeFilterField') || UsersSearchDateRangeField.CREATED_AT,
    sortOrder: getPageSettings('users', 'sortOrder') || SortOrder.DESC,
    sortField: getPageSettings('users', 'sortField') || 'createdAt',
    ...(getPageSettings('users', 'rolesFilter') && {
      rolesFilter: getPageSettings('users', 'rolesFilter'),
    }),
    ...(getPageSettings('users', 'onboardType') && {
      onboardType: getPageSettings('users', 'onboardType'),
    }),
    ...(getPageSettings('users', 'verificationStatus') && {
      verificationStatus: getPageSettings('users', 'verificationStatus'),
    }),
    selectedUserPaymentMethods: [],
  },
  selectedItemLoan: {
    collection: [],
    page: 1,
    pageSize: 8,
    pagesCount: 1,
    total: 0,
  },
  loans: {
    collection: loansAdapter.getInitialState(),
    dateRangeFilter: getSavedDates('loans'),
    dateRangeSettings: getPageSettings('loans', 'dateRangeSettings') || {
      type: DateRangeType.relative,
      timeUnits: DateRangeTimeUnits.months,
      value: 1,
    },
    isLoading: false,
    page: getPageSettings('loans', 'page') || 1,
    pageSize: getPageSettings('loans', 'size') || 8,
    pagesCount: 1,
    searchQuery: getPageSettings('loans', 'query') || '',
    total: 0,
    stage: getPageSettings('loans', 'status') || null,
    sortField: getPageSettings('loans', 'sortField') || 'createdAt',
    sortOrder: getPageSettings('loans', 'sortOrder') || SortOrder.DESC,
  },
  selectedItemTransaction: {
    collection: [],
    page: 1,
    pageSize: 8,
    pagesCount: 1,
    total: 0,
  },
  transactions: {
    collection: transactionsAdapter.getInitialState(),
    dateRangeFilter: getSavedDates('transactions'),
    dateRangeSettings: getPageSettings('transactions', 'dateRangeSettings') || {
      type: DateRangeType.relative,
      timeUnits: DateRangeTimeUnits.months,
      value: 1,
    },
    isLoading: false,
    page: getPageSettings('transactions', 'page') || 1,
    pageSize: getPageSettings('transactions', 'size') || 8,
    pagesCount: 1,
    searchQuery: getPageSettings('transactions', 'query') || '',
    total: 0,
    transferStatus: getPageSettings('transactions', 'transferStatus') || null,
    sortField: getPageSettings('transactions', 'sortField') || 'createdAt',
    sortOrder: getPageSettings('transactions', 'sortOrder') || SortOrder.DESC,
  },
  partners: {
    collection: partnersAdapter.getInitialState(),
    searchQuery: '',
    dateRangeFilter: getTimePeriod(30),
    isLoading: false,
    page: 1,
    pageSize: 8,
    pagesCount: 1,
    total: 0,
  },
  selectedDeeplinkPresets: [],
  selectedDeeplinkPreset: null,
  selectedDeeplinkPresetLoading: true,
  createdDeeplinkPreset: {
    uuid: '',
    link: '',
    shortLink: '',
  },
};

export const platformAdminSlice = createSlice({
  name: 'platformAdmin',
  initialState,
  reducers: {
    setUsersRolesFilter: (state, action: PayloadAction<UserRoleString[] | null>) => {
      if (!action.payload) {
        delete state.users.rolesFilter;
      }

      if (action.payload) {
        state.users.rolesFilter = action.payload;
      }

      state.users.page = 1;
    },

    setUsersDateFilterField: (state, action: PayloadAction<UsersSearchDateRangeField>) => {
      state.users.dateRangeFilterField = action.payload;
      state.users.page = 1;
    },

    setUserOnboardingType: (state, action: PayloadAction<UsersOnboardingType>) => {
      state.users.onboardType = action.payload;
      state.users.page = 1;
    },

    setUserVerificationStatus: (state, action: PayloadAction<UsersVerificationStatus>) => {
      state.users.verificationStatus = action.payload;

      if (action.payload === UsersVerificationStatus.GUEST) {
        state.users.rolesFilter = [UserRoleString.GUEST];
      }

      if (
        action.payload === UsersVerificationStatus.FULLY_VERIFIED ||
        action.payload === UsersVerificationStatus.PARTIALLY_VERIFIED
      ) {
        state.users.rolesFilter = [UserRoleString.PHONE_VERIFIED, UserRoleString.EMAIL_VERIFIED];
      }

      state.users.page = 1;
    },
    // TABLES REDUCERS (GENERIC)
    setTablePageSize: (state, action: PayloadAction<SetTablePageSizePayload>) => {
      const { size, tableName } = action.payload;
      const { total } = state[tableName];
      state[tableName].page = 1;
      state[tableName].pageSize = size;
      state[tableName].pagesCount = Math.ceil(total / size);
    },
    setTablePage: (state, action: PayloadAction<SetTablePagePayload>) => {
      const { tableName, page } = action.payload;
      const { pagesCount } = state[tableName];

      if (page < 1 || page > pagesCount) return;

      state[tableName].page = page;
    },
    selectTableItem: (state, action: PayloadAction<SelectTableItemPayload>) => {
      const { tableName, id } = action.payload;
      state[tableName].selectedItemId = id;
    },
    resetTableItem: (state, action: PayloadAction<AdminTableName>) => {
      const tableName = action.payload;
      delete state[tableName].selectedItemId;
    },
    setTableSearchQuery: (state, action: PayloadAction<SetTableSearchQueryPayload>) => {
      const { tableName, query } = action.payload;
      state[tableName].searchQuery = query;
      state[tableName].page = 1;
    },
    setTableDateFilter: (state, action: PayloadAction<SetTableSearchDateFilterPayload>) => {
      const { tableName, range } = action.payload;
      state[tableName].dateRangeFilter = range;
      state[tableName].page = 1;
    },
    setTableStageFilter: (state, action: PayloadAction<SetTableStageFilterPayload>) => {
      const { tableName, stage } = action.payload;
      state[tableName].stage = stage;
      state[tableName].page = 1;
    },
    setTableTransferStatusFilter: (
      state,
      action: PayloadAction<SetTableTransferStatusFilterPayload>,
    ) => {
      const { tableName, transferStatus } = action.payload;
      state[tableName].transferStatus = transferStatus;
      state[tableName].page = 1;
    },
    setTableSortOrder: (state, action: PayloadAction<SetTableSortOrderPayload>) => {
      const { tableName, sortOrder } = action.payload;
      state[tableName].sortOrder = sortOrder;
      state[tableName].page = 1;
    },
    setTableSortField: (state, action: PayloadAction<SetTableSortFieldPayload>) => {
      const { tableName, sortField } = action.payload;

      state[tableName].sortField = sortField;
      state[tableName].sortOrder = SortOrder.DESC;
      state[tableName].page = 1;
    },

    setTableDateRangeSettings: (state, action: PayloadAction<SetTableDateRangeSettingsPayload>) => {
      const { tableName, dateRangeSettings } = action.payload;

      if (tableName === 'users' || tableName === 'loans' || tableName === 'transactions') {
        state[tableName].dateRangeSettings = dateRangeSettings;
      }
    },

    resetTable: (state, action: PayloadAction<AdminTableName>) => {
      const tableName = action.payload;
      state[tableName].isReset = true;
      state[tableName].searchQuery = '';
      state[tableName].page = 1;
      state[tableName].dateRangeFilter = [
        tableName === 'users'
          ? addYears(new Date(), -1).toISOString()
          : addDays(new Date(), -30).toISOString(),
        new Date().toISOString(),
      ];
      state[tableName].pageSize = 8;
      delete state[tableName].selectedItemId;
      delete state[tableName].stage;
      delete state[tableName].transferStatus;
      delete state[tableName].sortField;
      delete state[tableName].sortOrder;
      resetPageSettings('loans');
      resetPageSettings('transactions');
    },
    setIsReset: (state, action: PayloadAction<AdminTableName>) => {
      state[action.payload].isReset = false;
    },
    resetSelectLoan: (state) => {
      state.selectedItemLoan.collection = [];
    },
    resetSelectTransaction: (state) => {
      state.selectedItemTransaction.collection = [];
    },
    setSelectedDeeplinkPreset: (state, action: PayloadAction<LoanPresetDto | null>) => {
      state.selectedDeeplinkPreset = action.payload;
    },
    setSelectedDeeplinkPresets: (state, action: PayloadAction<LoanPresetDto[]>) => {
      state.selectedDeeplinkPresets = action.payload;
    },
    setSelectedDeeplinkPresetLoading: (state, action: PayloadAction<boolean>) => {
      state.selectedDeeplinkPresetLoading = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(adminUsersSearch.pending, (state) => {
        state.users.isLoading = true;
      })
      .addCase(adminUsersSearch.fulfilled, (state, action) => {
        const { users, total } = action.payload;
        const { pageSize } = state.users;

        usersAdapter.setAll(state.users.collection, users);

        state.users.total = total;
        state.users.pagesCount = Math.ceil(total / pageSize);
        state.users.isLoading = false;
      })

      .addCase(adminUserActiveControl.fulfilled, (state, action) => {
        const { userId, isActive } = action.payload;
        const currentState = state.users.collection.entities[userId];

        if (!currentState) return;

        const { roles } = currentState;

        const newRoles = isActive
          ? without(roles, UserRoleString.DEACTIVATED)
          : uniq(roles.concat(UserRoleString.DEACTIVATED));

        usersAdapter.updateOne(state.users.collection, {
          id: userId,
          changes: { roles: newRoles },
        });
      })

      // TODO: get rid of code-repeat
      .addCase(adminLoansSearch.pending, (state, action) => {
        state.loans.isLoading = true;
      })
      .addCase(adminLoansSearch.fulfilled, (state, action) => {
        const { loans, total } = action.payload;
        const { pageSize } = state.loans;

        loansAdapter.setAll(state.loans.collection, loans);

        state.loans.total = total;
        state.loans.pagesCount = Math.ceil(total / pageSize);
        state.loans.isLoading = false;
      })
      .addCase(adminLoan.fulfilled, (state, action) => {
        const { loans, total, skip, limit } = action.payload;
        const { pageSize, page } = state.selectedItemLoan;

        state.selectedItemLoan.collection = loans;
        state.selectedItemLoan.total = total;
        state.selectedItemLoan.pagesCount = Math.ceil(total / pageSize);
      })
      .addCase(adminTransactionsSearch.pending, (state, action) => {
        state.transactions.isLoading = true;
      })
      .addCase(adminTransactionsSearch.fulfilled, (state, action) => {
        const { transfers, total } = action.payload;
        const { pageSize } = state.transactions;

        transactionsAdapter.setAll(state.transactions.collection, transfers);

        state.transactions.total = total;
        state.transactions.pagesCount = Math.ceil(total / pageSize);
        state.transactions.isLoading = false;
      })
      .addCase(adminTransaction.fulfilled, (state, action) => {
        const { transfers, total, skip, limit } = action.payload;
        const { pageSize, page } = state.selectedItemLoan;

        state.selectedItemTransaction.collection = transfers;
        state.selectedItemTransaction.total = total;
        state.selectedItemTransaction.pagesCount = Math.ceil(total / pageSize);
      })
      // TODO: get rid of code-repeat
      .addCase(adminPartnersSearch.pending, (state, action) => {
        state.loans.isLoading = true;
      })
      .addCase(adminPartnersSearch.fulfilled, (state, action) => {
        const { partners, total } = action.payload;
        const { pageSize } = state.partners;

        partnersAdapter.setAll(state.partners.collection, partners);

        state.partners.total = total;
        state.partners.pagesCount = Math.ceil(total / pageSize);
        state.partners.isLoading = false;
      })
      .addCase(getUserPaymentMethods.fulfilled, (state, action) => {
        state.users.selectedUserPaymentMethods = action.payload;
      })
      .addCase(adminLoanPresetsList.fulfilled, (state, action) => {
        const { presets } = action.payload;
        const transformData = (dataFromBackend: LoanPresetDto[]) =>
          dataFromBackend.map((item) => ({
            dataId: item.uuid,
            name: item.shortLink ? item.shortLink : '',
            state: item.state,
            displayName: item.displayName,
            settings: {
              type: item.settings?.type,
              feeEnabled: item.settings?.feeEnabled,
              whoIsPaying: item.settings?.whoIsPaying,
              feeAmountPercentage: item.settings?.feeAmountPercentage,
              // @ts-ignore
              partnerComissionEnabled: item?.settings?.partnerComissionEnabled
                ? // @ts-ignore
                  item?.settings?.partnerComissionEnabled
                : undefined,
              // @ts-ignore
              partnerComissionPercentageAmount: item?.settings?.partnerComissionPercentageAmount
                ? // @ts-ignore
                  item?.settings?.partnerComissionPercentageAmount
                : undefined,
            },
            useFiona: item.useFiona,
            skipKYC: item.skipKYC,
          }));

        // @ts-ignore
        state.selectedDeeplinkPresets = transformData(presets);
      })
      .addCase(adminLoanPresetById.fulfilled, (state, action) => {
        state.selectedDeeplinkPreset = action.payload;
      })
      .addCase(adminLoanPresetsList.pending, (state) => {
        state.selectedDeeplinkPresetLoading = false;
      })
      .addCase(adminLoanPresetCreate.fulfilled, (state, action) => {
        state.createdDeeplinkPreset = action.payload;
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .addCase(adminLoanPresetActivate.fulfilled, () => {})
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .addCase(adminLoanPresetUpdate.fulfilled, () => {});
  },
});

export default platformAdminSlice;
export const { reducer: platformAdminReducer } = platformAdminSlice;
export const {
  // Users
  setUsersRolesFilter,
  setUsersDateFilterField,
  setTablePage,
  setTablePageSize,
  setTableSearchQuery,
  setTableDateFilter,
  selectTableItem,
  setUserOnboardingType,
  setUserVerificationStatus,
  resetTableItem,
  resetTable,
  setTableStageFilter,
  setTableTransferStatusFilter,
  setIsReset,
  resetSelectLoan,
  resetSelectTransaction,
  setTableSortField,
  setTableSortOrder,
  setTableDateRangeSettings,
  setSelectedDeeplinkPreset,
  setSelectedDeeplinkPresets,
  setSelectedDeeplinkPresetLoading,
  // Loans
} = platformAdminSlice.actions;
