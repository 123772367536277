import { FlowTypes } from '../../../auth/store';
import AffiliateFirst from '../../assets/AffiliateFirst.svg';
import AffiliateLogo from '../../assets/AffiliateLogo.svg';
import AffiliateSecond from '../../assets/AffiliateSecond.svg';
import BillPayFirst from '../../assets/BillPayFirst.svg';
import BillPayLogo from '../../assets/BillPayLogo.svg';
import BillPaySecond from '../../assets/BillPaySecond.svg';
import LoanCreatorFirst from '../../assets/LoanCreatorFirst.svg';
import LoanCreatorLogo from '../../assets/LoanCreatorLogo.svg';
import LoanCreatorSecond from '../../assets/LoanCreatorSecond.svg';

export const SelectionOptions = [
  {
    title: 'Client Service Suite',
    subtitle: 'Increase engagement and revenue.',
    info: 'Using our Financial Support Suite, you can retain members, boost loyalty, and increase revenue by seamlessly integrating Member Support or Decline Flow campaigns.',
    logo: AffiliateLogo,
    flowType: FlowTypes.AFFILIATE_PARTNER,
    image1: AffiliateFirst,
    image2: AffiliateSecond,
  },
  {
    title: 'Zirtue Pay',
    subtitle: 'Reduce delinquency rates',
    info: 'With Zirtue Pay, you can offer Zirtue as a payment option for your customers in need of a financial lifeline.',
    logo: BillPayLogo,
    flowType: FlowTypes.BILL_PAY,
    image1: BillPayFirst,
    image2: BillPaySecond,
  },
  {
    title: 'Loan Creator',
    subtitle: 'Deploy loans to trusted members',
    info: 'Develop and deploy loan offers for members of your community. Easily manage payments and track repayments all in one place.',
    logo: LoanCreatorLogo,
    flowType: FlowTypes.LOAN_CREATOR,
    image1: LoanCreatorFirst,
    image2: LoanCreatorSecond,
  },
] as const;
