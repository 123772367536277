import { FlowTypes } from '../../auth/store';

export type NestedLink = {
  link: string;
  title: string;
  id: string;
};

export type Tab = {
  title: string;
  linkTo: string;
  id: string;
  children: NestedLink[] | [];
  iconName: string;
  flowType: keyof typeof FlowTypes[];
};

const teamSettingsLinks: NestedLink[] = [
  {
    link: '/team-members',
    title: 'Team members',
    id: 'onboarding-step-2-2',
  },
  {
    link: '/roles',
    title: 'Roles',
    id: 'onboarding-step-2-3',
  },
];

const webhookSettingsLinks: NestedLink[] = [
  {
    link: '/developer-tools/sftp',
    title: 'Account Validation',
    id: 'webhook-settings-5-1',
  },
  {
    link: '/developer-tools/webhooks',
    title: 'Remittance',
    id: 'webhook-settings-5-2',
  },
];

const webhookSettingsLinksWebhooks: NestedLink[] = [
  {
    link: '/developer-tools/webhooks',
    title: 'Remittance',
    id: 'webhook-settings-5-2',
  },
];

const webhookSettingsLinksSftp: NestedLink[] = [
  {
    link: '/developer-tools/sftp',
    title: 'Account Validation',
    id: 'webhook-settings-5-1',
  },
];

export const loanCreatorLinks: NestedLink[] = [
  {
    link: '/loan-overview',
    title: 'Overview',
    id: 'loan-overview',
  },
  {
    link: '/loan-creation',
    title: 'Create Loan',
    id: 'loan-creation',
  },
  {
    link: '/loan-list',
    title: 'Loans',
    id: 'loan-list',
  },
  {
    link: '/loan-details',
    title: 'Loan Details',
    id: 'loan-details',
  },
];

export const loanCreatorTabs = [
  {
    title: 'Loan Creator',
    linkTo: '',
    id: 'loan-creator',
    children: loanCreatorLinks,
    flowType: ['billpay'],
    iconName: 'buffer',
  },
];

export const analyticsTabs = [
  {
    title: 'Analytics',
    linkTo: '/payments',
    id: 'onboarding-step-1',
    children: [],
    iconName: 'tachometer',
  },
];

export const teamSettingsTabs = [
  {
    title: 'Team Settings',
    linkTo: '',
    id: 'onboarding-step-2',
    children: teamSettingsLinks,
    iconName: 'teams',
  },
];

export const financesTabs: Tab[] = [
  {
    title: 'Bank',
    linkTo: '/finances',
    id: 'onboarding-step-3',
    children: [],
    // @ts-ignore
    flowType: [FlowTypes.LOAN_CREATOR, FlowTypes.BILL_PAY],
    iconName: 'bank',
  },
  {
    title: 'Loan Toolkit',
    linkTo: '/integrations',
    id: 'onboarding-step-4',
    children: [],
    // @ts-ignore
    flowType: [FlowTypes.AFFILIATE_PARTNER, FlowTypes.BILL_PAY],
    iconName: 'cubes',
  },
];

export const webhooksTabs = [
  {
    title: 'Developer Toolkit',
    linkTo: '',
    id: 'onboarding-step-5',
    children: webhookSettingsLinks,
    iconName: 'developerToolkit',
  },
];

export const webhooksTabsSftp = [
  {
    title: 'Developer Toolkit',
    linkTo: '',
    id: 'onboarding-step-5',
    children: webhookSettingsLinksSftp,
    iconName: 'developerToolkit',
  },
];

export const webhooksTabsWebhooks = [
  {
    title: 'Developer Toolkit',
    linkTo: '',
    id: 'onboarding-step-5',
    children: webhookSettingsLinksWebhooks,
    iconName: 'developerToolkit',
  },
];

export const getWebhooksTabs = (isSftpEnabled: boolean, isWebhooksEnabled: boolean) => {
  if (isSftpEnabled && isWebhooksEnabled) {
    return webhooksTabs;
  }
  if (isSftpEnabled) {
    return webhooksTabsSftp;
  }
  if (isWebhooksEnabled) {
    return webhooksTabsWebhooks;
  }
  return [];
};

//* This may be required in the future for Comms tab
// title = 'Comms';
// iconName = 'chats';
// id = 'onboarding-step-5';
// onClick = { handleFinanceClick };
// nestedLinks = { commsLinks };
