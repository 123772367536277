import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import React from 'react';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const data = {
    labels: ['Medical', 'Education', 'Emergency'],
    datasets: [
      {
        label: 'Total loans',
        data: [10, 60, 20],
        backgroundColor: ['#66B5FF', '#003566', '#006ACC'],
        hoverBackgroundColor: ['#66B5FF', '#003566', '#006ACC'],
        borderWidth: 1,
        fill: false,
        pointStyle: 'rect',
        pointRadius: 5,
        pointBorderColor: 'rgb(0, 0, 0)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          boxWidth: 10,
          font: { size: 10 },
        },
      },
      tooltip: {
        backgroundColor: 'rgb(255, 255, 255)',
        titleColor: '#14181F',
        bodyColor: '#14181F',
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 1,
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
