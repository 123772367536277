export enum Permissions {
  LOAN_CREATOR_VIEW = 'LOAN_CREATOR_VIEW',
  BILL_PAYMENTS_VIEW = 'BILL_PAYMENTS_VIEW',
  BILL_PAYMENTS_EXPORT = 'BILL_PAYMENTS_EXPORT',
  CUSTOMERS_ADD = 'CUSTOMERS_ADD',
  CUSTOMERS_REFUND = 'CUSTOMERS_REFUND',
  FINANCES_VIEW = 'FINANCES_VIEW',
  FINANCES_MANAGE = 'FINANCES_MANAGE',
  MEMBERS_INVITE = 'MEMBERS_INVITE',
  MEMBERS_MANAGE = 'MEMBERS_MANAGE',
  UNRECOGNIZED = 'UNRECOGNIZED',
  PLATFORM_ADMIN_VIEW = 'PLATFORM_ADMIN_VIEW',
  PLATFORM_ADMIN_EDIT = 'PLATFORM_ADMIN_EDIT',
  ACCESS_DEVELOPER_TOOLS = 'ACCESS_DEVELOPER_TOOLS',
  ACCESS_REMIT_FILE_LOGS = 'ACCESS_REMIT_FILE_LOGS',
}

export const ORG_MBR_DEACTIVATED = 'ORG_MBR_DEACTIVATED';
export const ORG_ADMIN = 'ORG_ADMIN';

export default Permissions;
