import { Link } from 'react-router-dom';

import { useAppDispatch } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import { Typography } from '../../init-setup/components';
import { setShowBanner } from '../store/dashboardSlice';

export default function Banner() {
  const dispatch = useAppDispatch();

  return (
    <div className="banner">
      <div className="banner__description-сontainer">
        <div className="banner__description">
          <Typography
            tag="span"
            size="body-sm"
            weight="bold"
            decoration="underline"
            colors="text-white"
          >
            Zirtue Pay,
          </Typography>

          <div className="banner__description-text">
            <Typography tag="span" size="body-sm" colors="text-white" className="banner__text">
              is now available. A simplified, no-code payment solution for clients.
            </Typography>
            <Icon name="banner-icon" classes="banner-icon" />
          </div>
        </div>

        <Link
          to="/integrations"
          onClick={() => {
            dispatch(setShowBanner(false));
          }}
        >
          <Typography
            className="banner__link"
            tag="p"
            size="body-sm"
            weight="bold"
            decoration="underline"
            colors="text-white"
          >
            Discover Zirture Pay today!
          </Typography>
        </Link>
      </div>
      <button
        type="button"
        className="banner__close"
        onClick={() => dispatch(setShowBanner(false))}
      >
        <Icon name="tag-close" />
      </button>
    </div>
  );
}
