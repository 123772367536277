import cn from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Input } from '../../../../shared';
import { FormWrapper } from '../../../init-setup/components';
import administration from '../../assets/business-type-form/administration.svg';
import agriculture from '../../assets/business-type-form/agriculture.svg';
import artsEntertainment from '../../assets/business-type-form/arts-entertainment.svg';
import construction from '../../assets/business-type-form/construction.svg';
import educationalServices from '../../assets/business-type-form/educational-services.svg';
import financeOrInsurance from '../../assets/business-type-form/finance-or-insurance.svg';
import healthcareAndSocialAssistance from '../../assets/business-type-form/healthcare-and-social-assistance.svg';
import informationTechnology from '../../assets/business-type-form/information-technology.svg';
import manufacturingWholesale from '../../assets/business-type-form/manufacturing-wholesale.svg';
import other from '../../assets/business-type-form/other.svg';
import realEstate from '../../assets/business-type-form/real-estate.svg';
import realTrade from '../../assets/business-type-form/retail-trade.svg';
import scientificAndTechnicalServices from '../../assets/business-type-form/scientific-and-technical-services.svg';
import transportationAndWarehousing from '../../assets/business-type-form/transportation-and-warehousing.svg';
import { TLoanBusinessType, setLoanBusinessType } from '../../store';

const bussinesTypes = [
  {
    id: 0,
    title: 'Administration',
    image: administration,
  },
  {
    id: 1,
    title: 'Agriculture, Farming',
    image: agriculture,
  },
  {
    id: 2,
    title: 'Arts, Entertainment and Recreation',
    image: artsEntertainment,
  },
  {
    id: 3,
    title: 'Construction',
    image: construction,
  },
  {
    id: 4,
    title: 'Educational Services',
    image: educationalServices,
  },
  {
    id: 5,
    title: 'Finance or Insurance',
    image: financeOrInsurance,
  },
  {
    id: 6,
    title: 'Healthcare and Social Assistance',
    image: healthcareAndSocialAssistance,
  },
  {
    id: 7,
    title: 'Information, Technology',
    image: informationTechnology,
  },
  {
    id: 8,
    title: 'Manufacturing, Wholesale',
    image: manufacturingWholesale,
  },
  {
    id: 9,
    title: 'Scientific and Technical Services',
    image: scientificAndTechnicalServices,
  },
  {
    id: 10,
    title: 'Real Estate, Rental and Leasing',
    image: realEstate,
  },
  {
    id: 11,
    title: 'Retail Trade',
    image: realTrade,
  },
  {
    id: 12,
    title: 'Transportation and Warehousing',
    image: transportationAndWarehousing,
  },
  {
    id: 13,
    title: 'Other',
    image: other,
  },
];

type FormData = {
  loanBusinessType: string;
};

type FormicDataTypes = {
  currentStepIndex: number;
  values: FormData;
  errors: FormikErrors<FormData>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldError: (field: string, message: string) => void;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

export default function SelectBusinessTypeForm({
  currentStepIndex,
  errors,
  handleChange,
  setFieldError,
  handleBlur,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
}: FormicDataTypes) {
  const { loanBusinessType } = useAppSelector((state) => state.loans);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<TLoanBusinessType | undefined>();
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [keyboardNav, setKeyboardNav] = useState<boolean>(false);
  const businessTypeRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleChangeType = (selectedBusiness: TLoanBusinessType, viaKeyboard = false) => {
    if (selectedBusiness.title !== 'Other') {
      setFieldValue('loanBusinessType', selectedBusiness.title);
    } else {
      setFieldValue('loanBusinessType', 'other-custom');
      setFieldTouched('loanBusinessType', false);
    }
    dispatch(setLoanBusinessType(selectedBusiness));
    setValue(selectedBusiness);
    if (viaKeyboard) {
      setFocusedIndex(bussinesTypes.findIndex((rt) => rt.id === selectedBusiness.id));
      setKeyboardNav(true);
    } else {
      setFocusedIndex(null);
      setKeyboardNav(false);
    }
  };

  const fieldChangeNew = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFieldTouched('loanBusinessType', true);
    setFieldValue(target.id, target.value);
  };

  const handleBlurCustom = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
    setFieldTouched('loanBusinessType', true);
    if (values.loanBusinessType === 'other-custom') {
      setFieldError('loanBusinessType', 'Please select a business type');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
      event.preventDefault();
      const newIndex = focusedIndex !== null ? (focusedIndex + 1) % bussinesTypes.length : 0;
      setFocusedIndex(newIndex);
      setKeyboardNav(true);
    } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
      event.preventDefault();
      const newIndex =
        focusedIndex !== null
          ? (focusedIndex - 1 + bussinesTypes.length) % bussinesTypes.length
          : bussinesTypes.length - 1;
      setFocusedIndex(newIndex);
      setKeyboardNav(true);
    } else if (event.key === 'Enter' && focusedIndex !== null) {
      handleChangeType(bussinesTypes[focusedIndex], true);
    }
  };

  useEffect(() => {
    if (keyboardNav && focusedIndex !== null) {
      businessTypeRefs.current[focusedIndex]?.focus();
    }
  }, [focusedIndex, keyboardNav]);

  useEffect(() => {
    if (loanBusinessType) {
      setValue(loanBusinessType);
    }
  }, [currentStepIndex, loanBusinessType]);

  return (
    <FormWrapper>
      {/* eslint-disable-next-line */}
      <div className="loans__selectLoanTypeBusinessForm" onKeyDown={handleKeyDown} tabIndex={-1}>
        <div className="loans__selectLoanTypeBusinessForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">What best describes the business type?</h2>
            <div className="loanTypeForm__subtitle">Select one option that applies</div>
          </div>
          <div className="loans__selectLoanTypeBusinessForm__body">
            <div className="loans__selectLoanTypeBusinessForm__selectItems">
              {bussinesTypes.map((el, index) => (
                // eslint-disable-next-line
                <button
                  key={el.id}
                  type="button"
                  // eslint-disable-next-line no-return-assign
                  ref={(el) => (businessTypeRefs.current[index] = el)}
                  className={cn(
                    'loans__selectLoanTypeBusinessForm__selectItem',
                    value?.id === index
                      ? 'loans__selectLoanTypeBusinessForm__selectItem--active'
                      : '',
                    focusedIndex === index
                      ? 'loans__selectLoanTypeBusinessForm__selectItem--focused'
                      : '',
                  )}
                  onClick={() => {
                    handleChangeType(el);
                    setFocusedIndex(null);
                  }}
                  tabIndex={0}
                >
                  <div className="loans__selectLoanTypeBusinessForm__selectItem__left">
                    <div className="loans__selectLoanTypeBusinessForm__selectItem__imageBlock">
                      <img src={el.image} alt={el.title} />
                    </div>
                    <div className="loans__selectLoanTypeBusinessForm__selectItem__title">
                      {el.title}
                    </div>
                  </div>
                  <div className="loans__selectLoanTypeBusinessForm__selectItem__dot" />
                </button>
              ))}
            </div>
            {value?.title === 'Other' ? (
              <div className="loans__selectLoanTypeBusinessForm__input">
                <Input
                  type="text"
                  id="loanBusinessType"
                  maxLength={50}
                  placeholder="Please specify the reason"
                  value={values.loanBusinessType === 'other-custom' ? '' : values.loanBusinessType}
                  onChange={fieldChangeNew}
                  onBlur={handleBlurCustom}
                  error={
                    Boolean(errors.loanBusinessType) &&
                    touched.loanBusinessType &&
                    'Please enter the reason'
                  }
                  showAttentionErrorIcon={false}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
