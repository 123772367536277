import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { Icon } from '../../../../../shared';

import CampaignModificationTab from './CampaignModificationTab';
import LoanCreationTab from './LoanCreationTab';

export default function TabsBlock() {
  return (
    <div>
      <Tabs className="tabs tabs--payments">
        <TabList className="tabs__list">
          <Tab className="tabs__item" selectedClassName="tabs__item--selected">
            Loan Creation Modification
          </Tab>
          <Tab className="tabs__item" selectedClassName="tabs__item--selected">
            ZP/DP Campaign Modification
          </Tab>
        </TabList>
        <TabPanel className="tabs__panel">
          <div className="partners-tabs__note">
            <Icon name="glyph" />
            <span>
              Note: Changes to the settings below will only affect the Loan Creator and Payment
              Arrangement products.
            </span>
          </div>
          <LoanCreationTab />
        </TabPanel>
        <TabPanel className="tabs__panel">
          <div className="partners-tabs__note">
            <Icon name="glyph" />
            <span>
              Note: The changes below pertain solely to Campaigns, and Campaigns are exclusively
              linked to the Zirtue Pay and Decline Flow products.
            </span>
          </div>
          <CampaignModificationTab />
        </TabPanel>
      </Tabs>
    </div>
  );
}
