import cn from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Input } from '../../../../shared';
import { FormWrapper } from '../../../init-setup/components';
import auto from '../../assets/reason-form/auto.svg';
import business from '../../assets/reason-form/business.svg';
import creditCard from '../../assets/reason-form/credit-card.svg';
import education from '../../assets/reason-form/education.svg';
import emergency from '../../assets/reason-form/emergency.svg';
import entertainment from '../../assets/reason-form/entertainment.svg';
import food from '../../assets/reason-form/food.svg';
import insurance from '../../assets/reason-form/insurance.svg';
import medical from '../../assets/reason-form/medical.svg';
import other from '../../assets/reason-form/other.svg';
import rent from '../../assets/reason-form/rent.svg';
import travel from '../../assets/reason-form/travel.svg';
import utilities from '../../assets/reason-form/utilities.svg';
import { TLoanReasonType, setLoanReasonType } from '../../store';

const reasonTypes = [
  {
    id: 0,
    title: 'Auto',
    image: auto,
  },
  {
    id: 1,
    title: 'Business',
    image: business,
  },
  {
    id: 2,
    title: 'Credit Card',
    image: creditCard,
  },
  {
    id: 3,
    title: 'Education',
    image: education,
  },
  {
    id: 4,
    title: 'Emergency',
    image: emergency,
  },
  {
    id: 5,
    title: 'Entertainment',
    image: entertainment,
  },
  {
    id: 6,
    title: 'Food',
    image: food,
  },
  {
    id: 7,
    title: 'Insurance',
    image: insurance,
  },
  {
    id: 8,
    title: 'Medical',
    image: medical,
  },
  {
    id: 9,
    title: 'Travel',
    image: travel,
  },
  {
    id: 10,
    title: 'Rent',
    image: rent,
  },
  {
    id: 11,
    title: 'Utilities',
    image: utilities,
  },
  {
    id: 12,
    title: 'Other',
    image: other,
  },
];

type FormData = {
  loanReasonType: string | undefined;
};

type FormicDataTypes = {
  currentStepIndex: number;
  values: FormData;
  errors: FormikErrors<FormData>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldError: (field: string, message: string) => void;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

export default function SelectReasonTypeForm({
  currentStepIndex,
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
  setFieldError,
  setFieldValue,
  setFieldTouched,
}: FormicDataTypes) {
  const { loanReasonType } = useAppSelector((state) => state.loans);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<TLoanReasonType | undefined>();
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [keyboardNav, setKeyboardNav] = useState<boolean>(false);
  const reasonTypeRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleChangeType = (selectedReason: TLoanReasonType, viaKeyboard = false) => {
    if (selectedReason.title !== 'Other') {
      setFieldValue('loanReasonType', selectedReason.title);
    } else {
      setFieldValue('loanReasonType', 'other-custom');
      setFieldTouched('loanReasonType', false);
    }
    dispatch(setLoanReasonType(selectedReason));
    setValue(selectedReason);
    if (viaKeyboard) {
      setFocusedIndex(reasonTypes?.findIndex((rt) => rt.id === selectedReason.id));
      setKeyboardNav(true);
    } else {
      setFocusedIndex(null);
      setKeyboardNav(false);
    }
  };

  const fieldChangeNew = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(target.id, target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
      event.preventDefault();
      const newIndex = focusedIndex !== null ? (focusedIndex + 1) % reasonTypes?.length : 0;
      setFocusedIndex(newIndex);
      setKeyboardNav(true);
    } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
      event.preventDefault();
      const newIndex =
        focusedIndex !== null
          ? (focusedIndex - 1 + reasonTypes?.length) % reasonTypes?.length
          : reasonTypes?.length - 1;
      setFocusedIndex(newIndex);
      setKeyboardNav(true);
    } else if (event.key === 'Enter' && focusedIndex !== null) {
      handleChangeType(reasonTypes[focusedIndex], true);
    }
  };

  useEffect(() => {
    if (keyboardNav && focusedIndex !== null) {
      reasonTypeRefs.current[focusedIndex]?.focus();
    }
  }, [focusedIndex, keyboardNav]);

  useEffect(() => {
    if (loanReasonType) {
      setValue(loanReasonType);
    }
  }, [currentStepIndex, loanReasonType]);

  const handleBlurCustom = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
    setFieldTouched('loanReasonType', true);
    if (values.loanReasonType === 'other-custom') {
      setFieldError('loanReasonType', 'Please select a business type');
    }
  };

  return (
    <FormWrapper>
      {/* eslint-disable-next-line */}
      <div className="loans__selectLoanReasonTypeForm" onKeyDown={handleKeyDown} tabIndex={-1}>
        <div className="loans__selectLoanReasonTypeForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">What&lsquo;s the reason for the loan?</h2>
            <div className="loanTypeForm__subtitle">Please select one option that applies.</div>
          </div>
          <div className="loans__selectLoanReasonTypeForm__body">
            <div className="loans__selectLoanReasonTypeForm__selectItems">
              {reasonTypes?.map((el, index) => (
                // eslint-disable-next-line
                <button
                  key={el.id}
                  type="button"
                  // eslint-disable-next-line no-return-assign
                  ref={(el) => (reasonTypeRefs.current[index] = el)}
                  className={cn(
                    'loans__selectLoanReasonTypeForm__selectItem',
                    value?.id === index
                      ? 'loans__selectLoanReasonTypeForm__selectItem--active'
                      : '',
                    focusedIndex === index
                      ? 'loans__selectLoanReasonTypeForm__selectItem--focused'
                      : '',
                  )}
                  onClick={() => {
                    handleChangeType(el);
                    setFocusedIndex(null);
                  }}
                  tabIndex={0}
                >
                  <div className="loans__selectLoanReasonTypeForm__selectItem__left">
                    <div className="loans__selectLoanReasonTypeForm__selectItem__imageBlock">
                      <img src={el.image} alt={el.title} />
                    </div>
                    <div className="loans__selectLoanReasonTypeForm__selectItem__title">
                      {el.title}
                    </div>
                  </div>
                  <div className="loans__selectLoanReasonTypeForm__selectItem__dot" />
                </button>
              ))}
            </div>
            {value?.title === 'Other' ? (
              <div className="loans__selectLoanReasonTypeForm__input">
                <Input
                  type="text"
                  id="loanReasonType"
                  maxLength={50}
                  placeholder="Please specify the reason"
                  value={values.loanReasonType === 'other-custom' ? '' : values.loanReasonType}
                  onChange={fieldChangeNew}
                  onBlur={handleBlurCustom}
                  error={
                    Boolean(errors.loanReasonType) &&
                    touched.loanReasonType &&
                    'Please enter the reason'
                  }
                  showAttentionErrorIcon={false}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
