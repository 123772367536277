export enum Industry {
  Accounting = 'Accounting',
  Advertising = 'Advertising, Arts & Media',
  Airline = 'Airline',
  Automotive = 'Automotive',
  Banking = 'Banking & Financial Services',
  Design = 'Design & Architecture',
  Education = 'Education & Training',
  Government = 'Government & Defense',
  Healthcare = 'Healthcare & Medical',
  Hospitality = 'Hospitality & Tourism',
  HumanResources = 'Human Resources & Recruitment',
  Information = 'Information & Communication Technology',
  Insurance = 'Insurance & Superannuation',
  Legal = 'Legal',
  Manufacturing = 'Manufacturing, Transport & Logistics',
  Marketing = 'Marketing & Communications',
  Mining = 'Mining, Resources & Energy',
  RealEstate = 'Real Estate & Property',
  Retail = 'Retail & Consumer Products',
  Sales = 'Sales',
  Science = 'Science & Technology',
  Sport = 'Sport & Recreation',
  Trades = 'Trades & Services',
  Transportation = 'Transportation',
}

export const COMPANY_SIZE = {
  SMALL: '1 - 1,000 employees',
  MEDIUM: '1,000 - 5,000 employees',
  LARGE: '5,000 - 10,000 employees',
  EXTRA_LARGE: '10,000+ employees',
} as const;
