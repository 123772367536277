import { FC, useState } from 'react';

import { useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';

import { HeaderMenuItem } from './HeaderMenuItem';

import { UserNav } from './index';

export const HeaderMenu: FC = () => {
  const [openSettings, setOpenSettings] = useState(false);

  const partner = useAppSelector((store) => store.auth.partner);

  if (!partner) {
    return null;
  }

  const { name, icons } = partner.details;
  const menuItems = [
    {
      id: 'Settings',
      title: 'Settings',
      iconName: 'cog',
      onClick: () => setOpenSettings(true),
      children: (
        <UserNav
          avatarUrl={icons?.highRes || '/images/dashboard/avatar-114x114.png'}
          name={name}
          type="Account owner"
          open={openSettings}
          setOpen={setOpenSettings}
        />
      ),
    },
  ];

  return (
    <ul className="dashboard-header__menu">
      {menuItems.map(({ title, iconName, id, onClick, children }, index) => (
        <HeaderMenuItem
          key={id}
          id={id}
          title={title}
          iconName={iconName}
          onClick={onClick}
          index={index}
        >
          {children}
        </HeaderMenuItem>
      ))}
    </ul>
  );
};
