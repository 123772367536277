import { useFlag } from '@unleash/proxy-client-react';
import has from 'lodash/has';
import { FC, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { FlowTypes } from '../../features/auth/store';
import { FeatureTogglesNames } from '../../main/env/feature-toggle-names';
import { useAppSelector } from '../../main/store/hooks';
import { ORG_ADMIN, Permissions } from '../constants/Permissions';

type PermissonsPredicateOr = { $or: (PermissonsPredicateAnd | Permissions)[] };
type PermissonsPredicateAnd = { $and: (PermissonsPredicateOr | Permissions)[] };

export type PermissonsPredicate = PermissonsPredicateAnd | PermissonsPredicateOr;

const isOr = (val: PermissonsPredicate): val is PermissonsPredicateOr => has(val, '$or');

const isAnd = (val: PermissonsPredicate): val is PermissonsPredicateAnd => has(val, '$and');

const isPermission = (val: PermissonsPredicate | Permissions): val is Permissions =>
  typeof val === 'string';

const verifyPermissions = (
  orgPermissions: string[],
  predicate: PermissonsPredicate | Permissions,
): boolean => {
  if (isPermission(predicate)) return orgPermissions.includes(predicate);

  if (isOr(predicate)) {
    return !!predicate.$or.find((p) => verifyPermissions(orgPermissions, p));
  }

  if (isAnd(predicate)) {
    return predicate.$and.every((p) => verifyPermissions(orgPermissions, p));
  }

  return false;
};

interface PermissionsGuardProps {
  permissions?: PermissonsPredicate;
  /** @default true */
  bypassForAdmin?: boolean;
  redirect?: string;
  title?: string;
}

/**
 * Renders children
 * - if authenticated user's permissions match the given permissions predicate
 * - if `bypassForAdmin` prop is not `false` and authenticated user has `ORG_ADMIN` role
 *
 * If redirect prop is provided,
 * it will render Redirect with given value instead of just hiding the children
 */
export const PermissionsGuard: FC<PermissionsGuardProps> = ({
  permissions,
  bypassForAdmin = true,
  children,
  redirect,
  title,
}) => {
  const { finishedSetup, authToken, partner, loading, checklistFinished } = useAppSelector(
    (state) => state.auth,
  );
  const { orgPermissions, orgRoles } = authToken ?? {};
  const PARTNER_DBP_WEBHOOKS = useFlag(FeatureTogglesNames.PARTNER_DBP_WEBHOOKS);
  const PARTNER_P2P_WEBHOOKS = useFlag(FeatureTogglesNames.PARTNER_P2P_WEBHOOKS);
  const PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD = useFlag(
    FeatureTogglesNames.PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD,
  );
  const PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD = useFlag(
    FeatureTogglesNames.PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD,
  );
  const PARTNER_DBP_DEVELOPER_TOOLKIT = useFlag(FeatureTogglesNames.PARTNER_DBP_DEVELOPER_TOOLKIT);
  const PARTNER_P2P_DEVELOPER_TOOLKIT = useFlag(FeatureTogglesNames.PARTNER_P2P_DEVELOPER_TOOLKIT);

  const canRender = useMemo<boolean>(() => {
    const isOnboardingPage = window.location.href.endsWith('/init-setup');
    const isBankPage = window.location.href.endsWith('/finances');
    const isLoanCreatorPages = window.location.href.includes('/loan');
    const isIntegrationsPage = window.location.href.includes('/integrations');
    const isWebHooksPages = window.location.href.includes('/developer-tools');
    const isWebHooksСonfigurationPage = window.location.href.includes(
      '/developer-tools/webhooks/configuration',
    );
    const isWebHooksSftpPages = window.location.href.includes('/developer-tools/sftp');

    if (isOnboardingPage && checklistFinished) return false;

    if (!finishedSetup && !isOnboardingPage && partner?.details.flowType) return false;

    if (
      isBankPage &&
      partner?.details.flowType &&
      partner?.details.flowType === FlowTypes.AFFILIATE_PARTNER
    )
      return false;

    if (loading && isLoanCreatorPages && partner?.details.flowType !== FlowTypes.LOAN_CREATOR)
      return false;

    if (loading && isIntegrationsPage && partner?.details.flowType === FlowTypes.LOAN_CREATOR)
      return false;

    if (loading && isWebHooksPages && partner?.details.flowType === FlowTypes.LOAN_CREATOR)
      return false;

    if (loading && isWebHooksСonfigurationPage) {
      if (partner?.details.flowType === FlowTypes.AFFILIATE_PARTNER && PARTNER_P2P_WEBHOOKS) {
        return false;
      }
      if (partner?.details.flowType === FlowTypes.BILL_PAY && PARTNER_DBP_WEBHOOKS) {
        return false;
      }
    }

    if (loading && isWebHooksSftpPages) {
      if (
        partner?.details.flowType === FlowTypes.AFFILIATE_PARTNER &&
        PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD
      ) {
        return false;
      }
      if (
        partner?.details.flowType === FlowTypes.BILL_PAY &&
        PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD
      ) {
        return false;
      }
    }

    if (loading && isWebHooksPages) {
      if (
        partner?.details.flowType === FlowTypes.AFFILIATE_PARTNER &&
        PARTNER_P2P_DEVELOPER_TOOLKIT
      ) {
        return false;
      }
      if (partner?.details.flowType === FlowTypes.BILL_PAY && PARTNER_DBP_DEVELOPER_TOOLKIT) {
        return false;
      }
    }

    if (!orgRoles || !orgPermissions) return false;

    if (!permissions) return true;

    if (orgRoles.includes(ORG_ADMIN) && bypassForAdmin) return true;

    return verifyPermissions(orgPermissions, permissions);
  }, [
    loading,
    orgPermissions,
    orgRoles,
    permissions,
    bypassForAdmin,
    finishedSetup,
    partner,
    checklistFinished,
    PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD,
    PARTNER_DBP_DEVELOPER_TOOLKIT,
    PARTNER_DBP_WEBHOOKS,
    PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD,
    PARTNER_P2P_DEVELOPER_TOOLKIT,
    PARTNER_P2P_WEBHOOKS,
  ]);

  if (!canRender && redirect) return <Redirect to={{ pathname: redirect, state: { title } }} />;

  return <>{canRender ? children : null}</>;
};
