import classNames from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { CURRENT_ENV, GOOGLE_PLACES } from '../../../main/env';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Input, Label, Select } from '../../../shared';
import { Alert, Icon, MaskInput, Progress } from '../../../shared/components';
import { DataItem } from '../../../shared/components/Select';
import { Address, NodeType } from '../../../shared/models/AddAchNodeDto';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { AlertType, addAlert } from '../../../shared/store/modals';
import { getContact } from '../../../shared/utils';
import { SelectedPlace } from '../../finances/modals/ModalAddPaymentMethod';
import { addressFromGooglePlace } from '../../finances/store/actions/addAchNode';
import { AuthBannerContent } from '../components/AuthBanner';
import { AuthHeaderContent } from '../components/AuthHeader';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { setAuthToken } from '../store';
import { setStep } from '../store';
import { createAccount } from '../store/actions/createAccount';
import { validateDomain } from '../store/actions/validateDomain';

enum Industry {
  Accounting = 'Accounting',
  Advertising = 'Advertising, Arts & Media',
  Airline = 'Airline',
  Automotive = 'Automotive',
  Banking = 'Banking & Financial Services',
  Design = 'Design & Architecture',
  Education = 'Education & Training',
  Government = 'Government & Defense',
  Healthcare = 'Healthcare & Medical',
  Hospitality = 'Hospitality & Tourism',
  HumanResources = 'Human Resources & Recruitment',
  Information = 'Information & Communication Technology',
  Insurance = 'Insurance & Superannuation',
  Legal = 'Legal',
  Manufacturing = 'Manufacturing, Transport & Logistics',
  Marketing = 'Marketing & Communications',
  Mining = 'Mining, Resources & Energy',
  RealEstate = 'Real Estate & Property',
  Retail = 'Retail & Consumer Products',
  Sales = 'Sales',
  Science = 'Science & Technology',
  Sport = 'Sport & Recreation',
  Trades = 'Trades & Services',
  Transportation = 'Transportation',
}

interface FormValues {
  name: string;
  email: string;
  phone: string;
  domain: string;
  address: string;
  industry: string;
}
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const domainRegExp = /^(?!-|\.)[a-zA-Z0-9.-]+\w+$/;

const validationStep1 = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number to short')
    .max(12, 'Phone number to long'),
});

const validationStep2 = yup.object({
  domain: yup.string().required().matches(domainRegExp, 'Domain is not valid'),
});

const isValid = (addr: Address): boolean =>
  !(
    isEmpty(addr.city) ||
    isEmpty(addr.country) ||
    isEmpty(addr.line1) ||
    isEmpty(addr.state) ||
    isEmpty(addr.zip)
  );

const addrError = (addr: Address): string => {
  let errorStr = '';

  if (isEmpty(addr.city)) {
    errorStr = 'City';
  } else if (isEmpty(addr.country)) {
    errorStr = 'Country';
  } else if (isEmpty(addr.line1)) {
    errorStr = 'Country';
  } else if (isEmpty(addr.state)) {
    errorStr = 'State';
  } else if (isEmpty(addr.zip)) {
    errorStr = 'Zip';
  }

  return `${errorStr} is missing in this address`;
};
const validationStep3 = yup.object({
  address: yup
    .object()
    .label('Business address')
    .required()
    .test(
      'Business address',
      (val) => {
        if (typeof val.value !== 'object') {
          return '';
        }

        const address = val.value;
        return addrError(address);
      },
      (val) => {
        const address = val as never as Address;
        return typeof address === 'object' && isValid(address);
      },
    ),
});

const validationStep4 = yup.object({
  industry: yup.string().required(),
});

export default function CreateAccount() {
  const [valid, setValid] = useState(true);
  const [schema, setSchema] = useState(validationStep1);
  const [phoneCode, setPhoneCode] = useState(1);
  const { step, isAuthenticated } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const industryData = Object.values(Industry).map<DataItem>((i) => ({ name: i, dataId: i }));
  const validationSchemas = [validationStep1, validationStep2, validationStep3, validationStep4];
  const postfixDomain = CURRENT_ENV() === 'dev' ? `.dev.portal.zirtue.com` : '.portal.zirtue.com';

  // Only user at this point is the ORG_ADMIN
  const { user, email } = useAppSelector((state) => state.auth);
  const admin = user?.contacts[0] ? user?.contacts[0] : undefined;

  const handleStep = (dir: 'next' | 'prev') => {
    if (step) {
      if (dir === 'next' && step < 4) {
        dispatch(setStep(step + 1));
      }
      if (dir === 'prev' && step >= 2) {
        setValid(true);
        dispatch(setStep(step - 1));
      }
    }
  };

  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      domain: '',
      place: '',
      address: undefined,
      industry: '',
    },
    onSubmit: async (values) => {
      if (step === 1) {
        Amplitude.logEvent(AmplitudeEvents.adminRegistrationStep1Completed);
      }

      if (step === 2) {
        const response = await dispatch(validateDomain(`${values.domain}${postfixDomain}`));

        if (validateDomain.fulfilled.match(response)) {
          if (!response.payload) {
            handleStep('next');
            Amplitude.logEvent(AmplitudeEvents.adminRegistrationStep2Completed, {
              url: `${values.domain}${postfixDomain}`,
            });
          } else {
            setFieldError('domain', 'Domain is already taken. Please choose another.');
          }
        }

        return;
      }

      if (step === 3) {
        Amplitude.logEvent(AmplitudeEvents.adminRegistrationStep3Completed);
      }

      if (step === 4) {
        const { name, email, phone, address, industry, domain } = values;

        if (address) {
          const response = await dispatch(
            createAccount({
              businessName: name,
              businessEmailUri: `mailto:${email}`,
              businessPhoneUri: `tel:+${phoneCode}${phone}`,
              frontEndBasePath: `${domain}${postfixDomain}`,
              principalAddress: address,
              adminEmail: admin,
              industry,
            }),
          );

          if (createAccount.fulfilled.match(response)) {
            if (response.payload.loginUrl) {
              setAuthToken(response.payload.ziamAuthToken);
              window.location.href = response.payload.loginUrl;
            }
          }

          if (createAccount.rejected.match(response)) {
            dispatch(
              addAlert({
                text: response.payload || 'Something went wrong',
                type: AlertType.error,
              }),
            );
          }
        }
        return;
      }
      handleStep('next');
    },
    validationSchema: schema,
  });

  const handleChangeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    const value = e.target.value
      .split(' ')
      .map((i) => i.trim().toLowerCase())
      .join('-');

    setFieldValue('domain', value);
  };

  // @ts-ignore
  const handleError = (id) => Boolean(errors[id]) && touched[id] && errors[id];

  useEffect(() => {
    // @ts-ignore
    setSchema(validationSchemas[step - 1]);
  }, [step]);

  useEffect(() => {
    setValid(isValid);
  }, [isValid]);

  useEffect(() => {
    setFieldValue('email', email, true);
  }, [email]);

  const handleAddPlace = (place: SelectedPlace) => {
    const dtoAddress: Address | null = addressFromGooglePlace(place);
    setFieldValue('place', place.formatted_address, false);
    setFieldValue('address', dtoAddress);
  };

  const handleChangePlace = () => {
    setFieldValue('place', '', false);
  };

  if (!isAuthenticated) {
    history.push('/signin');
  }

  return (
    <AuthorizationLayout
      headerContent={AuthHeaderContent.SignIn}
      bannerContent={AuthBannerContent.CreateAccount}
    >
      <Progress
        currentStep={step || 0}
        totalSteps={4}
        classes="auth__progress"
        progressTitle={`Step ${step} of 4`}
      />
      <form onSubmit={handleSubmit}>
        {/* Step 1 */}
        {step === 1 && (
          <div className="auth-form">
            <h2 className="auth-form__subtitle auth-form__subtitle--with-line">
              Register your business information
            </h2>
            {/* Form group */}
            <div className="form-group">
              <Label forId="name">Business name</Label>
              <Input
                type="text"
                id="name"
                placeholder="Zirtue sends payments to this name"
                value={values.name}
                onChange={handleChangeName}
                onBlur={handleBlur}
                error={handleError('name')}
              />
            </div>
            {/* Form group */}
            <div className="form-group">
              <Label forId="email">Business email</Label>
              <Input
                type="email"
                id="email"
                placeholder="Business email - example@example.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={handleError('email')}
              />
            </div>
            {/* Form group */}
            <div className="form-group">
              <Label forId="phone">Business phone number</Label>
              <div className="form-group__row form-group__row--phone-code">
                <div className="form-group__row-item form-group__row-item--phone-code">
                  <Select
                    id="business-phone-code"
                    haveFlag
                    data={[{ dataId: '1', name: '+1' }]}
                    value="1"
                    classes="select--small"
                    onChange={(e) => setPhoneCode(e)}
                  />
                </div>
                <div className="form-group__row-item">
                  <MaskInput
                    mask="999-999-9999"
                    id="phone"
                    inputClassName="input input-group__input"
                    placeholder="Add phone number"
                    value={values.phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                    }}
                    error={handleError('phone')}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Step 2 */}
        {step === 2 && (
          <div className="auth-form">
            <h2 className="auth-form__subtitle auth-form__subtitle--with-line">
              Setup your business portal domain
            </h2>
            {/* Form group */}
            <div className="form-group">
              <Label forId="domain">Custom URL</Label>
              <div className="form-group__row form-group__row--domain">
                <div className="form-group__row-item">
                  <Input
                    type="text"
                    id="domain"
                    placeholder="example"
                    value={values.domain}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={handleError('domain')}
                  />
                </div>
                <div className="form-group__row-item form-group__row-item--domain">
                  .portal.zirtue.com
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Step 3 */}
        {step === 3 && (
          <div className="auth-form">
            <h2 className="auth-form__subtitle auth-form__subtitle--with-line">
              Where is your company headquarters located?
            </h2>

            {/* Form group */}
            <div className="form-group">
              <Label forId="address" infoText="Enter your business address" arrowPosition="center">
                Business Address
              </Label>
              <div className="autocomplete">
                <Autocomplete
                  className={classNames('autocomplete__input', {
                    'autocomplete__input--error': errors.address,
                  })}
                  apiKey={GOOGLE_PLACES()}
                  placeholder="Enter your business address"
                  onPlaceSelected={handleAddPlace}
                  onChange={handleChangePlace}
                  defaultValue={values.place}
                  options={{
                    types: ['address'],
                    language: 'en',
                    componentRestrictions: { country: 'usa' },
                  }}
                />
                <div className="autocomplete__icon">
                  <Icon name="chevron" />
                </div>
                {errors.address && <div className="autocomplete__error">{errors.address}</div>}
              </div>
            </div>
          </div>
        )}
        {/* Step 4 */}
        {step === 4 && (
          <div className="auth-form">
            <h2 className="auth-form__subtitle auth-form__subtitle--with-line">
              In what industry does your business operate?
            </h2>

            {/* Form group */}
            <div className="form-group">
              <Label forId="industry">Industry</Label>
              <Select
                id="industry"
                placeholder="Enter business industry"
                data={industryData}
                value={values.industry}
                onChange={handleChange('industry')}
                error={handleError('industry')}
              />
            </div>
          </div>
        )}

        <div className="auth-form">
          <div
            className={classNames('auth-form__btn-row', {
              'auth-form__btn-row--center': step === 1,
            })}
          >
            {step && step > 1 && (
              <button
                type="button"
                className="button button--secondary-blue button--lg auth-form__btn-row-item"
                onClick={() => handleStep('prev')}
              >
                Back
              </button>
            )}
            <button
              type="submit"
              className="button button--primary-blue button--lg auth-form__btn-row-item"
              disabled={!valid}
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </AuthorizationLayout>
  );
}
