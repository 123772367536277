import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import { setToggleGetHelpPanel } from '../../auth/store';
import { setMenuOpen } from '../store/dashboardSlice';

import { HeaderMenu } from './HeaderMenu';

import { UserNav } from './index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function DashboardHeader() {
  const dispatch = useAppDispatch();
  const partner = useAppSelector((store) => store.auth.partner);
  const { finishedSetup, toggleGetHelpPanel } = useAppSelector((state) => state.auth);

  if (!partner) {
    return null;
  }

  const { name, icons } = partner.details;

  return (
    <header
      className={classNames(
        `dashboard-header ${!finishedSetup ? 'dashboard-header__init-screen' : ''}`,
      )}
    >
      {!finishedSetup ? (
        <>
          <div>
            <Icon name="zirtue-logo" classes="dashboard-header__logo" />
          </div>
          {/* eslint-disable-next-line */}
          <div
            className="dashboard-header__get-help"
            onClick={() => dispatch(setToggleGetHelpPanel(!toggleGetHelpPanel))}
          >
            Get help
            <Icon name="external-link-alt-solid" />
          </div>
        </>
      ) : (
        <div className="dashboard-header__top-row">
          <button
            type="button"
            className="dashboard-header__burger"
            onClick={() => {
              dispatch(setMenuOpen(true));
            }}
          >
            <Icon name="burger" classes="dashboard-header__burger-icon" />
          </button>
          <HeaderMenu />
          <div className="dashboard-header__wrap-logo">
            <div className="dashboard-header__line" />
            <Icon name="zirtue-logo" classes="dashboard-header__logo" />
          </div>
          <div className="dashboard-header__user-nav-row">
            <UserNav
              avatarUrl={icons?.highRes || '/images/dashboard/avatar-114x114.png'}
              name={name}
              type="Account owner"
            />
          </div>
        </div>
      )}
    </header>
  );
}
