import React from 'react';

import { News, NewsTitle } from './components';
import { newsStories } from './constants/newsStories';

const NewsStory = () => (
  <div className="news-stories">
    <NewsTitle />
    <div className="news-stories__container">
      {newsStories.length > 0 &&
        newsStories.map((news) => (
          <News
            key={news.id}
            description={news.description}
            id={news.id}
            link={news.link}
            preview={news.preview}
            title={news.title}
          />
        ))}
    </div>
  </div>
);

export default NewsStory;
