import { useFlag } from '@unleash/proxy-client-react';
import { useEffect, useState, useMemo } from 'react';

import { FeatureTogglesNames } from '../../../../main/env/feature-toggle-names';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon } from '../../../../shared';
import Button from '../../../../shared/components/Button';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import {
  FlowTypes,
  setCurrentStepSetup,
  setFinishedSetup,
  setSelectedFlow,
} from '../../../auth/store';
import { completeOnboarding } from '../../../auth/store/actions/completeOnboarding';
import { selectChecklistFlow } from '../../../auth/store/actions/selectChecklistFlow';
import LastStepScreen from '../../assets/LastStepScreen.png';
import FormWrapper from '../FormWrapper/FormWrapper';

import { SelectionOptions } from './data';

export default function SelectionFlow() {
  const TOGGLES_LOAN_CREATOR_APP = useFlag(FeatureTogglesNames.TOGGLES_LOAN_CREATOR_APP);

  const { currentStepSetup, selectedFlow } = useAppSelector((state) => state.auth);

  const [value, setValue] = useState<keyof typeof FlowTypes>(FlowTypes.UNKNOWN);
  const loading = useAppSelector((state) => state.auth.loading);

  const dispatch = useAppDispatch();

  async function determineFlow() {
    if (FlowTypes.AFFILIATE_PARTNER === value) {
      try {
        const completeOnboardingResponse = await dispatch(
          // @ts-ignore
          completeOnboarding({ flowType: value, data: undefined }),
        );

        if (completeOnboarding.fulfilled.match(completeOnboardingResponse)) {
          const selectChecklistFlowResponse = await dispatch(
            // @ts-ignore
            selectChecklistFlow({ flowType: 'affiliate' }),
          );

          if (selectChecklistFlow.fulfilled.match(selectChecklistFlowResponse)) {
            console.log('success');
          } else {
            const errorMessage =
              (selectChecklistFlowResponse.payload as string) || 'An error occurred.';
            dispatch(
              addAlert({
                text: errorMessage,
                type: AlertType.error,
              }),
            );
          }
        } else {
          const errorMessage =
            (completeOnboardingResponse.payload as string) || 'An error occurred.';
          dispatch(
            addAlert({
              text: errorMessage,
              type: AlertType.error,
            }),
          );
        }
      } catch (error) {
        dispatch(
          addAlert({
            text: 'There is an error occurring when attempting to select the flowtype.',
            type: AlertType.error,
          }),
        );
      }
    } else {
      dispatch(setSelectedFlow(value));
    }
  }

  useEffect(() => {
    setValue(selectedFlow);
  }, [currentStepSetup, selectedFlow]);

  const prevStep = () => {
    if (currentStepSetup === 2) {
      dispatch(setCurrentStepSetup(1));
      dispatch(setSelectedFlow(FlowTypes.UNKNOWN));
    }
  };

  const nextStep = () => {
    if (currentStepSetup === 1) {
      dispatch(setCurrentStepSetup(2));
      dispatch(setSelectedFlow(value));

      if (value === FlowTypes.AFFILIATE_PARTNER) {
        determineFlow();
      }
    }
  };

  const initSetupDescr =
    'We help connect businesses, customers, and their loved ones through personal IOUs. Select from the products below to begin offering Zirtue to your customers.';

  const getTitle = () => {
    if (currentStepSetup === 1) {
      return 'What will you use Zirtue for?';
    }
    return '';
  };

  const getDescription = () => {
    if (currentStepSetup === 1) {
      return initSetupDescr;
    }
    return '';
  };

  const filteredSelectionOptions = useMemo(
    () =>
      SelectionOptions.filter(
        (SelectionOption) =>
          SelectionOption.flowType !== FlowTypes.LOAN_CREATOR || TOGGLES_LOAN_CREATOR_APP,
      ),
    [TOGGLES_LOAN_CREATOR_APP],
  );

  return (
    <FormWrapper title={getTitle()} description={getDescription()}>
      <div className="init-setup__selectionFlow selectionFlow">
        {currentStepSetup === 1 ? (
          <div className="selectionFlow__wrapper">
            {filteredSelectionOptions.map((s) => (
              // eslint-disable-next-line
              <div
                key={s.title}
                className={`selectionFlow__card ${
                  value === s.flowType ? 'selectionFlow__card--active' : ''
                }`}
                onClick={() => {
                  if (value === s.flowType) {
                    setValue(FlowTypes.UNKNOWN);
                  } else {
                    setValue(s.flowType);
                  }
                }}
              >
                <div className="selectionFlow__card__header">
                  <div className="selectionFlow__card__header__left">
                    <div className="selectionFlow__card__logo">
                      <img src={s.logo} alt={s.title} />
                    </div>
                    <div className="selectionFlow__card__info">
                      <div className="selectionFlow__card__title">{s.title}</div>
                      <div className="selectionFlow__card__subtitle">{s.subtitle}</div>
                    </div>
                  </div>
                  <div className="selectionFlow__card__header__right">
                    <div className="selectionFlow__card__selectBtn">
                      <Icon name={value === s.flowType ? 'deselect-blue' : 'success-blue'} />
                      {value === s.flowType ? 'Deselect' : 'Select'}
                    </div>
                  </div>
                </div>
                <div className="selectionFlow__card__info">{s.info}</div>
                <div className="selectionFlow__card__imageList">
                  <div className="selectionFlow__card__imageItem">
                    <img src={s.image1} alt={s.title} />
                  </div>
                  <div className="selectionFlow__card__imageItem">
                    <img src={s.image2} alt={s.title} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ''
        )}
        {FlowTypes.AFFILIATE_PARTNER === value && currentStepSetup === 2 ? (
          <div className="init-setup__footer--complete">
            <div className="init-setup__footer--complete__imageBlock">
              <div className="init-setup__footer--complete__imageBlock__text">
                <div className="init-setup__footer--complete__imageBlock__title">
                  Your account is created
                </div>
                <div className="init-setup__footer--complete__imageBlock__subtitle">
                  On track to drive community engagement,
                  <br />
                  one loan at a time.
                </div>
              </div>
              <img src={LastStepScreen} alt="" />
            </div>
            <div className="init-setup__complete-text">
              Now that you&apos;ve successfully created your account, you can access the Quick-start
              guide to add team members, assign roles, integrate and access communications to drive
              awareness and payments.
            </div>
            <Button
              disabled={loading}
              type="button"
              className="button--primary-blue init-setup__button init-setup__button--complete"
              onClick={() => dispatch(setFinishedSetup(true))}
            >
              Go to dashboard
            </Button>
          </div>
        ) : (
          <div className="init-setup__footer">
            {currentStepSetup === 1 ? (
              ''
            ) : (
              <button
                disabled={loading}
                type="button"
                className="init-setup__button--prev"
                onClick={() => prevStep()}
              >
                Previous
              </button>
            )}
            <Button
              disabled={loading || value === FlowTypes.UNKNOWN}
              type="button"
              className="button--primary-blue init-setup__button"
              onClick={() => nextStep()}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </FormWrapper>
  );
}
