import { findLastIndex } from 'lodash';
import React, { useCallback } from 'react';

import { Icon } from '../../../../shared';
import { LoanStage } from '../../dtos/LoanTypes';
import { RepaymentPlanPayment } from '../../dtos/RepaymentPlan';
import { PaymentStatuses, RepaymentPlanPaymentDto } from '../../dtos/RepaymentPlanDto';

import LoanScheduleItem from './LoanScheduleItem';
import LoanScheduleWarningBlock from './LoanScheduleWarningBlock';

// interface Schedule {
//   day: string;
//   amount: number;
//   status: string;
// }

interface LoanScheduleProps {
  payments: Array<{ status: PaymentStatuses; payment: RepaymentPlanPayment }>;
  loanRepaymentDateFrom?: string;
  drawFail?: boolean;
  drawFailSystemIssue?: boolean;
  loanStage?: LoanStage;
}
export default function LoanSchedule({
  payments,
  loanRepaymentDateFrom,
  drawFail,
  drawFailSystemIssue,
  loanStage,
}: LoanScheduleProps) {
  const isMissedButFailed = useCallback(
    (item: { status: PaymentStatuses; payment: RepaymentPlanPayment }) =>
      item.status === PaymentStatuses.failed && item.payment.isCurrent && drawFail,
    [drawFail],
  );

  const isOneTime = payments.length === 1;

  const lastMissedIndex = findLastIndex(
    payments,
    (value) => !value.payment.isCurrent && value.status === PaymentStatuses.failed,
  );

  const hasFailedPayment = drawFail || drawFailSystemIssue;

  return (
    <ul className="loan__schedule">
      {payments.map((item, index) => (
        <LoanScheduleItem
          key={index}
          payment={item.payment}
          status={isMissedButFailed(item) ? PaymentStatuses.missed : item.status}
          isOneTime={isOneTime}
          loanRepaymentDateFrom={loanRepaymentDateFrom}
          drawFail={drawFail && item.payment.isCurrent}
          drawFailSystemIssue={drawFailSystemIssue && item.payment.isCurrent}
          loanStage={loanStage}
          drawMissed={index === lastMissedIndex && !hasFailedPayment}
        />
      ))}
      {/* {items.map(({ day, amount, status }) => (
        <li className="loan__schedule__item">
          <div>
            <div className="loan__schedule__item__content-wrapper">
              <div className="loan__schedule__item__icon">
                <Icon name="computer" />
              </div>
              <div className="loan__schedule__item__content">
                <span>{day}</span>
                <div>
                  <Icon name={getStatusIcon(status)} />
                  <span>{status}</span>
                </div>
              </div>
            </div>
            <span>
${amount}</span>
          </div>
          {(status === 'late' || status === 'missed') && (
            <LoanScheduleWarningBlock status={status} />
          )}
        </li>
      ))} */}
    </ul>
  );
}
