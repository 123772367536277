import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { useAppDispatch } from '../../../../main/store/hooks';
import { AuthTokenCreateDto, ServerError } from '../../../../shared';
import StoreKeys from '../../../../shared/constants/AsyncStoreKeys';
import {
  AuthTokenCreateModel,
  AuthTokenCreateResponse,
  AuthTokenCreateResponseDto,
} from '../../../../shared/models/AuthToken';
import { Amplitude, AmplitudeEvents } from '../../../../shared/services/amplitude';
import { addAlert, AlertType } from '../../../../shared/store/modals';

import type { AppDispatch, RootState } from '../../../../main/store';

export const authWithSecret = createAsyncThunk<
  AuthTokenCreateResponse,
  AuthTokenCreateDto,
  { dispatch: AppDispatch; rejectValue: ServerError; state: RootState }
>('auth/authWithSecret', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post<AuthTokenCreateResponseDto>('/auth/token', payload);
    const response = AuthTokenCreateModel.fromDTO(data);
    if (response.orgRoles.includes('ORG_MBR_DEACTIVATED')) {
      Amplitude.logEvent(AmplitudeEvents.accessRestricted);
      return rejectWithValue({
        message: 'MEMBER_DEACTIVATED',
        statusCode: '403',
      });
    }

    localStorage.setItem(StoreKeys.authToken, JSON.stringify(response));

    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});
