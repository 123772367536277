import React from 'react';

const NewsTitle = () => (
  <div className="news-stories__head">
    <h2 className="news-stories__title">Recent News</h2>
    <div className="news-stories__subtitle">
      Stay up to date with the latest news here at Zirtue!
    </div>
  </div>
);

export default NewsTitle;
