import React, { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { hasRole } from '../../features/auth/utils';
import DashboardLayout from '../../features/dashboard/layout/DashboardLayout';
import { useAppSelector } from '../../main/store/hooks';
import Permissions from '../constants/Permissions';
import { Amplitude, AmplitudeEvents } from '../services/amplitude';

const pages = {
  401: {
    pageTitle: 'Access denied',
    title: 'Oops, you don’t have access',
    text: 'You don’t have permission to view the page you’re looking for. Please reach out to your portal admin to gain access to this page.',
    image: '/images/errors/401.svg',
  },
  404: {
    pageTitle: 'Page not found',
    title: 'Oops, you’re lost in space',
    text: 'You don’t have permission to view the page you’re looking for. Please reach out to your portal admin to gain access to this page.',
    image: '/images/errors/404.svg',
  },
};

const PageNotFound = ({
  location,
}: {
  location: { pathname: string; state: { title: string } };
}) => {
  const code = location.pathname === '/access-denied' ? 401 : 404;
  const page = pages[code];
  const pageTitle = code === 401 ? location.state.title : page.pageTitle;
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);

  const isAdminPanel =
    authInfo &&
    (hasRole(authInfo, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(authInfo, Permissions.PLATFORM_ADMIN_VIEW));

  useEffect(() => {
    if (!isAdminPanel) {
      Amplitude.logEvent(AmplitudeEvents.accessRestricted, { error: code });
    }
  }, []);

  return (
    <DashboardLayout pageTitle={pageTitle}>
      <div className="not-found">
        <div className="not-found__image">
          <img src={page.image} alt="" />
        </div>
        <h3 className="not-found__title">{page.title}</h3>
        <p className="not-found__txt">{page.text}</p>
      </div>
    </DashboardLayout>
  );
};
export default PageNotFound;
