import cn from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import Button from '../../../shared/components/Button';
import { checkIsPhone } from '../../../shared/utils';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { getPaymentMethods } from '../../finances/store/actions';
import LoanOverviewSection from '../components/Loan/LoanOverviewSection';
import LoanSummary from '../components/Loan/LoanSummary';
import MainTabs from '../components/Loan/MainTabs';
import RepaymentPlan from '../components/Loan/RepaymentPlan';
import LoanSummaryStatus from '../components/LoanSummaryStatus/LoanSummaryStatus';
import { LoanClosureType, LoanStageKind } from '../dtos/LoanTypes';
import { setIsLoanShareScreenShow } from '../store';
import { getLoanDetails } from '../store/actions/getLoanDetails';
import { loanShareApi } from '../store/actions/loanShareApi';

export default function LoanPage() {
  const { id: loanId } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { loanDetails, loanDeeplinkIsLoading, createdLoanIsLoading, isLoanShareScreenShow } =
    useAppSelector((state) => state.loans);
  const { paymentMethods } = useAppSelector((state) => state.finances, shallowEqual);
  const activePaymentMethod = paymentMethods.find((method) => method.isActive);
  const [mainTab, setMainTab] = useState(1);

  const handleMainTab = useCallback(() => {
    setMainTab(mainTab === 1 ? 2 : 1);
  }, [mainTab, setMainTab]);

  const isRepaymantScreen =
    loanDetails?.stage.kind === LoanStageKind.repayment ||
    (loanDetails?.stage.kind === LoanStageKind.closed &&
      loanDetails.stage.closureType === LoanClosureType.forgiven) ||
    (loanDetails?.stage.kind === LoanStageKind.closed &&
      loanDetails.stage.closureType === LoanClosureType.paidOut) ||
    (loanDetails?.deactivatedAt && loanDetails?.movedToRepaymentAt);

  const username = loanDetails?.borrower.redactedUsername;
  const initialTargetName = loanDetails?.terms.description.initialTargetName;
  const displayBorrowerName = username
    ? !checkIsPhone(username)
      ? username
      : initialTargetName
    : '';

  useEffect(() => {
    // @ts-ignore
    dispatch(loanShareApi(loanId));
    // @ts-ignore
    dispatch(getPaymentMethods());

    return () => {
      dispatch(setIsLoanShareScreenShow(false));
    };
  }, []);

  useEffect(() => {
    if (loanId) {
      // @ts-ignore
      dispatch(getLoanDetails(loanId));
    }
  }, [loanId, dispatch]);

  return (
    <DashboardLayout isRounded={!isRepaymantScreen}>
      {/* eslint-disable-next-line */}
      {loanDeeplinkIsLoading || createdLoanIsLoading ? (
        <div className="loans__multiStepForm__form">
          <div className="multiStepForm__step loans__multiStepForm__form__container loans__multiStepForm__form__container--centered">
            <div className="loans__multiStepForm__form__container__loader">
              <div className="loader" />
              {isLoanShareScreenShow ? (
                <div className="loans__multiStepForm__form__container__loader__text">
                  Creating your offer...
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {isLoanShareScreenShow ? (
            <div className="loans__footer">
              <div className="loans__footer__right loans__footer__right--lastStep">
                <div className="loans__footer__totalAmount">
                  <button
                    type="button"
                    className="loans__button--prev loans__button--prev--isDisabled"
                    disabled
                  >
                    Previous
                  </button>
                </div>
                <Button type="button" className="button--primary-blue loans__button" disabled>
                  Next
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : isRepaymantScreen ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 24 }}>
          <div style={{ width: '100%' }}>
            <h1 className="loan__title">
              Loan to
              {` ${loanDetails?.borrower.redactedUsername.split(' ')[0]}`}
            </h1>
            <MainTabs value={mainTab} changeTab={handleMainTab} />
            {mainTab === 1 && <RepaymentPlan activePaymentMethod={activePaymentMethod} />}
            {mainTab === 2 && (
              <LoanSummary loanDetails={loanDetails} displayBorrowerName={displayBorrowerName} />
            )}
          </div>

          <LoanOverviewSection />
        </div>
      ) : (
        <LoanSummaryStatus
          loanDetails={loanDetails}
          activePaymentMethod={activePaymentMethod}
          displayBorrowerName={displayBorrowerName}
        />
      )}
    </DashboardLayout>
  );
}
