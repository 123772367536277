import React from 'react';

import { Icon } from '../../../shared';

type Props = {
  initials: string;
  photoUrl?: string;
  onFileSelect?: (file: File) => void;
  rerender: number;
};

const UploadPhoto: React.FC<Props> = ({ initials, photoUrl, onFileSelect, rerender }) => {
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const oneFile = e.target.files[0];

    if (onFileSelect) {
      onFileSelect(oneFile);
    }
  };

  return (
    <div className="upload-photo">
      <input
        type="file"
        id="upload-photo"
        className="upload-photo__input"
        onChange={handleFileInput}
      />
      <label htmlFor="upload-photo" className="upload-photo__label">
        {initials && <p className="upload-photo__first-letters">{initials}</p>}
        {photoUrl && (
          <img src={`${photoUrl}?${rerender}`} alt={initials} className="upload-photo__image" />
        )}
        <p className="upload-photo__cover">
          <Icon name="image" classes="upload-photo__cover-icon" />
          {photoUrl ? 'Change' : 'Upload'}
          <br />
          photo
        </p>
      </label>
      <p className="upload-photo__hint">
        Must be 80 x 80px. Logo must be at least 40 x 40px.
        <br />
        PNG file only.
      </p>
    </div>
  );
};

export default UploadPhoto;
