import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { api } from '../../../../main/network';
import { RootState } from '../../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import Button from '../../../../shared/components/Button';
import useMultistepForm from '../../../../shared/hooks/useMultistepForm';
import { Address } from '../../../../shared/models/AddAchNodeDto';
import { AlertType, addAlert, setModalSuccessOpen } from '../../../../shared/store/modals';
import { FlowTypes, setCurrentStepSetup } from '../../../auth/store';
import { completeOnboarding } from '../../../auth/store/actions/completeOnboarding';
import { selectChecklistFlow } from '../../../auth/store/actions/selectChecklistFlow';
import { setShowBanner } from '../../../dashboard/store/dashboardSlice';
import { firstSchema, secondSchema } from '../FormSchema';
import { Connect, Register } from '../FormSteps';
import LastStep from '../SelectionFlow/LastStep';

export type FormData = {
  ein: string;
  address?: Address;
  routingNumber: string;
  accountNumber: string;
  businessName: string;
};

export type OnboardingBusinessData = {
  businessName: string;
  ein: string;
  principalAddress: Address;
  routingNumber: string;
  accountNumber: string;
};

const INITIAL_DATA: FormData = {
  ein: '',
  address: undefined,
  businessName: '',
  routingNumber: '',
  accountNumber: '',
};

export enum SuccessfulStatus {
  successTitle = 'Bank account pending verification',
  successText = 'We will verify this bank account by sending micro-deposits. It may take 1 to 2 business days for these deposits to show up in the connected account. We will remind you to verify them.',
}

export default function MultiStepForm() {
  const addressRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const businessName = useSelector((state: RootState) => state.auth.partner?.details.name);
  function currentValidation(index: number) {
    const arr = [firstSchema, secondSchema];
    const validation = arr[index];
    return validation;
  }

  const [companyAddres, setCompanyAddres] = useState('');

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isValid,
    validateForm,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    setErrors,
    setTouched,
  } = useFormik({
    initialValues: INITIAL_DATA,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: currentValidation(currentStepIndex),
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubmit: onSubmitHandler,
  });

  const propsData = {
    values,
    handleChange,
    handleBlur,
    validateField,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    resetForm,
  };

  const [loading, setLoading] = useState(false);
  const goToLastStep = () => {
    setCurrentStepIndex(2);
  };

  const { currentStepSetup, selectedFlow } = useAppSelector((state) => state.auth);

  const { step, isLastStep, back, next, isRequestStep, isFirstStep } = useMultistepForm({
    steps: [
      <Register addressRef={addressRef} {...propsData} setCompanyAddres={setCompanyAddres} />,
      <Connect
        {...propsData}
        loading={loading}
        next={goToLastStep}
        setLoading={setLoading}
        companyAddres={companyAddres}
      />,
      <LastStep />,
    ],
    currentStepIndex,
    setCurrentStepIndex,
  });

  const updatePartner = async (data: { postalAddress: string }) => {
    try {
      await api.put('/partners', data);
      return true;
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Error while creating account.',
          type: AlertType.error,
        }),
      );
    } finally {
      setLoading(false);
    }
    return false;
  };

  async function determineFlow(flowType: keyof typeof FlowTypes, data?: OnboardingBusinessData) {
    try {
      // @ts-ignore
      const completeOnboardingResponse = await dispatch(completeOnboarding({ flowType, data }));

      if (completeOnboarding.fulfilled.match(completeOnboardingResponse)) {
        await updatePartner({ postalAddress: companyAddres });
        const selectChecklistFlowResponse = await dispatch(
          // @ts-ignore
          selectChecklistFlow({ flowType: 'billpay' }),
        );

        if (selectChecklistFlow.fulfilled.match(selectChecklistFlowResponse)) {
          dispatch(setCurrentStepSetup(4));
          dispatch(
            setModalSuccessOpen({
              opened: true,
              title: SuccessfulStatus.successTitle,
              text: SuccessfulStatus.successText,
            }),
          );
          next();
        } else {
          const errorMessage =
            (selectChecklistFlowResponse.payload as string) || 'An error occurred.';
          dispatch(
            addAlert({
              text: errorMessage,
              type: AlertType.error,
            }),
          );
        }
      } else {
        const errorMessage = (completeOnboardingResponse.payload as string) || 'An error occurred.';
        dispatch(
          addAlert({
            text: errorMessage,
            type: AlertType.error,
          }),
        );
      }
    } catch (error) {
      dispatch(
        addAlert({
          text: 'An unexpected error occurred.',
          type: AlertType.error,
        }),
      );
    }
  }

  async function onSubmitHandler(value: FormData) {
    if (isValid && !isRequestStep) {
      next();
    }

    if (isLastStep) {
      dispatch(setShowBanner(true));
    }

    if (isRequestStep) {
      const data: OnboardingBusinessData = {
        businessName: value.businessName,
        ein: values.ein.replace(/\D/g, ''),
        principalAddress: values.address!,
        routingNumber: values.routingNumber,
        accountNumber: values.accountNumber,
      };
      determineFlow(selectedFlow, data);
    }
  }

  function handleBlack() {
    if (isFirstStep) {
      validateForm();
      dispatch(setCurrentStepSetup(1));
      validateForm();
    }
    if (currentStepSetup === 3) {
      validateForm();
      dispatch(setCurrentStepSetup(2));
      validateForm();
    }

    back();
  }

  // eslint-disable-next-line consistent-return
  const buttonDisabled = () => {
    if (isFirstStep && values.ein && values.address?.country && isValid) {
      return false;
    }
    if (currentStepIndex === 1 && values.routingNumber && values.accountNumber && isValid) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setFieldValue('businessName', businessName);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFieldTouched('businessName');
      setErrors({});
      setTouched({});
    }, 0);
  }, [currentStepIndex]);

  return (
    <>
      <div className="init-setup__multiStepForm multiStepForm_container">
        <form onSubmit={handleSubmit} className="multiStepForm__form">
          <div className="multiStepForm__step">{step}</div>
          {isLastStep ? (
            ''
          ) : (
            <div className="init-setup__footer">
              {currentStepSetup === 1 || isLastStep ? (
                ''
              ) : (
                <button
                  disabled={loading}
                  type="button"
                  className="init-setup__button--prev"
                  onClick={handleBlack}
                >
                  Previous
                </button>
              )}
              <Button
                disabled={loading || buttonDisabled()}
                type="submit"
                className="button--primary-blue init-setup__button"
                onClick={() => {
                  if (isFirstStep && values.ein && values.address?.country && isValid) {
                    dispatch(setCurrentStepSetup(3));
                  }

                  if (
                    currentStepIndex === 1 &&
                    values.routingNumber &&
                    values.accountNumber &&
                    isValid
                  ) {
                    dispatch(setCurrentStepSetup(3));
                  }

                  if (isLastStep) {
                    dispatch(setCurrentStepSetup(4));
                  }
                }}
              >
                Next
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
