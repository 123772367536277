import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';

import { FeatureTogglesNames } from '../../../../main/env/feature-toggle-names';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { FormWrapper } from '../../../init-setup/components';
import newLoan from '../../assets/new-loan.svg';
import preExistingLoan from '../../assets/pre-existing-loan.svg';
import successWhite from '../../assets/success-white.svg';
import { LoanType, setLoanType } from '../../store';

type FormData = {
  loanType: string;
};

type FormicDataTypes = {
  currentStepIndex: number;
  values: Pick<FormData, 'loanType'>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

export default function LoanTypeForm({
  currentStepIndex,
  handleChange,
  handleBlur,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
}: FormicDataTypes) {
  const TOGGLES_LOAN_CREATOR_TYPE_NEW = useFlag(FeatureTogglesNames.TOGGLES_LOAN_CREATOR_TYPE_NEW);
  const TOGGLES_LOAN_CREATOR_TYPE_PRE_EXISTING = useFlag(
    FeatureTogglesNames.TOGGLES_LOAN_CREATOR_TYPE_PRE_EXISTING,
  );

  const { loanType } = useAppSelector((state) => state.loans);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState('');
  const handleChangeType = (value: string) => {
    dispatch(setLoanType(value));
    setFieldValue('loanType', value);
    // @ts-ignore
    setValue(value);
  };

  useEffect(() => {
    setValue(loanType);
  }, [currentStepIndex, loanType]);

  return (
    <FormWrapper>
      <div className="loanTypeForm">
        <div className="loanTypeForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">Pick your loan type</h2>
            <div className="loanTypeForm__subtitle">
              Below you have the option to initiate a new loan or manage the repayments of an
              outstanding loan.
            </div>
          </div>
          <div className="loanTypeForm__body">
            {TOGGLES_LOAN_CREATOR_TYPE_NEW && (
              <>
                {/* eslint-disable-next-line */}
                <div
                  className={classNames(
                    'loanTypeForm__typeSelect',
                    value === LoanType.NEW_LOAN ? 'loanTypeForm__typeSelect--active' : '',
                  )}
                  onClick={() => handleChangeType(LoanType.NEW_LOAN)}
                >
                  <div className="loanTypeForm__typeSelect__left">
                    <img src={newLoan} alt="newLoan" />
                    <div className="loanTypeForm__typeSelect__descr">
                      <div className="loanTypeForm__typeSelect__title">New loan</div>
                      <div className="loanTypeForm__typeSelect__subtitle">
                        Create a loan offer for a member of your community through Zirtue.
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'loanTypeForm__typeSelect__radio',
                      value === LoanType.NEW_LOAN ? 'loanTypeForm__typeSelect__radio--active' : '',
                    )}
                  >
                    <img src={successWhite} alt="successWhite" />
                  </div>
                </div>
              </>
            )}

            {TOGGLES_LOAN_CREATOR_TYPE_PRE_EXISTING && (
              <>
                {/* eslint-disable-next-line */}
                <div
                  className={classNames(
                    'loanTypeForm__typeSelect',
                    value === LoanType.PRE_EXISTINS_LOAN ? 'loanTypeForm__typeSelect--active' : '',
                  )}
                  onClick={() => handleChangeType(LoanType.PRE_EXISTINS_LOAN)}
                >
                  <div className="loanTypeForm__typeSelect__left">
                    <img src={preExistingLoan} alt="preExistingLoan" />
                    <div className="loanTypeForm__typeSelect__descr">
                      <div className="loanTypeForm__typeSelect__title">Pre-existing loan</div>
                      <div className="loanTypeForm__typeSelect__subtitle">
                        Create repayment request for a member of your community through Zirtue.
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'loanTypeForm__typeSelect__radio',
                      value === LoanType.PRE_EXISTINS_LOAN
                        ? 'loanTypeForm__typeSelect__radio--active'
                        : '',
                    )}
                  >
                    <img src={successWhite} alt="successWhite" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
