import { FC, useEffect, useState } from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

import { Icon } from '../../../shared';

import { NestedLink } from './nestedLinks';

interface DashboardNavItemProps {
  linkTo: string;
  title: string;
  iconName?: string;
  nestedLinks: NestedLink[];
  className?: string;
  id?: string;
  onClick?: () => void;
  toolkitMenuOpen?: boolean;
}

export const DashboardNavItem: FC<DashboardNavItemProps> = ({
  linkTo,
  title,
  iconName,
  id,
  nestedLinks,
  className,
  onClick,
  toolkitMenuOpen,
}) => {
  if (!linkTo && (!nestedLinks || nestedLinks.length === 0)) {
    console.error('Error: At least one of linkTo or nestedLinks should be provided');
    return null;
  }

  const location = useLocation();
  const currentRoute = location.pathname;
  const tabOpened =
    nestedLinks.length > 0 && nestedLinks.some((el) => currentRoute.indexOf(el.link) >= 0);

  const [toggleDropdown, setToggleDropdown] = useState(tabOpened);

  const chevronStyle = {
    transform: toggleDropdown ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease',
  };

  return (
    <li className="dashboard-nav__page" id={id}>
      {nestedLinks?.length && !linkTo ? (
        <div className="dashboard-nav__dropdown">
          <div
            className={`dashboard-nav__page-link ${className || ''} ${
              toggleDropdown ? 'dashboard-nav__page-link--active' : ''
            }`}
            role="button"
            onClick={() => setToggleDropdown((prev) => !prev)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setToggleDropdown((prev) => !prev);
              }
            }}
            tabIndex={0}
          >
            <div className="dashboard-nav__page-content">
              {iconName && (
                <div className="dashboard-nav__page-icon-box">
                  <Icon name={iconName} classes="dashboard-nav__page-icon" />
                </div>
              )}
              <p className="dashboard-nav__page-title">{title}</p>
            </div>
            <span style={chevronStyle}>
              <Icon name="chevron" classes="dashboard-nav__page-icon" />
            </span>
          </div>
          <ul className={`dashboard-nav__dropdown__dropdown-menu ${toggleDropdown ? 'open' : ''}`}>
            {nestedLinks
              .filter((link) => link.id !== 'loan-details')
              .map((link) => (
                <DashboardNavItem
                  key={link.id}
                  title={link.title}
                  linkTo={link.link}
                  className="nested-link"
                  nestedLinks={[]}
                />
              ))}
          </ul>
        </div>
      ) : (
        <NavLink
          onClick={onClick}
          to={linkTo!}
          className={`dashboard-nav__page-link ${className || ''}`}
          activeClassName="dashboard-nav__page-link--active-sub"
        >
          <div className="dashboard-nav__page-content">
            {iconName && (
              <div className="dashboard-nav__page-icon-box">
                <Icon name={iconName} classes="dashboard-nav__page-icon" />
              </div>
            )}
            <p className="dashboard-nav__page-title">{title}</p>
          </div>
        </NavLink>
      )}
    </li>
  );
};
