import React from 'react';

interface AnalyticsSectionProps {
  title: string;
  value: number;
  percent: number;
}
export default function AnalyticsSection({ title, value, percent }: AnalyticsSectionProps) {
  return (
    <div style={{ minWidth: 140 }}>
      <h3 className="loans-overview__metric-section__title">{title}</h3>
      <div className="loans-overview__metric-section__analytic-value">{value}</div>
      <div className="loans-overview__metric-section__percent-block">
        <div className="loans-overview__metric-section__percent-container">
          <div className="triangle" />
          <div className="loans-overview__metric-section__percent-value">{percent}%</div>
        </div>

        <div className="loans-overview__metric-section__percent-text">vs last month</div>
      </div>
    </div>
  );
}
