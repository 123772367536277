import { useState, useCallback } from 'react';

import { DateRangeFilter, DateRangeTuple, Label, Select } from '../../../../shared';

export default function Filters() {
  const [reason, setReason] = useState('');

  const mockData = [
    { dataId: 'Any', name: 'Any' },
    { dataId: 'Auto', name: 'Auto' },
    { dataId: 'Business', name: 'Business' },
    { dataId: 'Credit Card', name: 'Credit Card' },
    { dataId: 'Education', name: 'Education' },
    { dataId: 'Emergency', name: 'Emergency' },
    { dataId: 'Entertainment', name: 'Entertainment' },
    { dataId: 'Food', name: 'Food' },
    { dataId: 'Insurance', name: 'Insurance' },
    { dataId: 'Medical', name: 'Medical' },
    { dataId: 'Travel', name: 'Travel' },
    { dataId: 'Rent', name: 'Rent' },
    { dataId: 'Utilities', name: 'Utilities' },
    { dataId: 'Other', name: 'Other' },
  ];

  const handleDateFilter = useCallback((arg: DateRangeTuple) => {
    // temporarily decision
    console.log('arg', arg);
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
      <div style={{ width: 340 }}>
        <div className="form-group">
          <Label forId="category">Reason for loan</Label>
          <Select
            id="category"
            placeholder="Enter reason for loan"
            data={mockData}
            value={reason}
            classes="select--small"
            onChange={(e) => setReason(e)}
            disabled
          />
        </div>
      </div>
      <div style={{ marginBottom: 0 }} className="table-filter__col">
        <DateRangeFilter
          classes="table-filter__date-filter"
          onChangePeriod={(value) => handleDateFilter(value)}
          placeholder="Select date"
          disabled
        />
      </div>
    </div>
  );
}
