export enum FeatureTogglesNames {
  TOGGLES_HEALTHCHECK = 'partners-portal-toggles-health',
  TOGGLES_LOAN_CREATOR_APP = 'lc-product',
  TOGGLES_LOAN_CREATOR_TYPE_NEW = 'lc-loan-type-lo',
  TOGGLES_LOAN_CREATOR_TYPE_PRE_EXISTING = 'lc-loan-type-rr',
  TOGGLES_LOAN_CREATOR_ANALYTICS_TAB = 'lc-nav-analytics',
  PARTNER_DBP_WEBHOOKS = 'partner-dbp-webhooks',
  PARTNER_P2P_WEBHOOKS = 'partner-p2p-webhooks',
  PARTNER_DBP_DEVELOPER_TOOLKIT = 'partner-dbp-developer-toolkit',
  PARTNER_P2P_DEVELOPER_TOOLKIT = 'partner-p2p-developer-toolkit',
  PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD = 'partner-dbp-account-validation-upload',
  PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD = 'partner-p2p-account-validation-upload',
  PARTNER_DBP_BILL_PAY_POPUP = 'partner-dbp-bill-pay-popup',
  PARTNER_P2P_BILL_PAY_POPUP = 'partner-p2p-bill-pay-popup',
}
