import classNames from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { FormWrapper } from '../../../init-setup/components';
import businessEntity from '../../assets/business-entity.svg';
import employee from '../../assets/employee.svg';
import { LoanBorrowerType, setLoanBorrowerType } from '../../store';

type FormData = {
  loanBusinessType?: string | undefined;
};

type FormicDataTypes = {
  currentStepIndex: number;
  values: FormData;
  errors: FormikErrors<FormData>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

export default function SelectBorrowerTypeForm({
  currentStepIndex,
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
}: FormicDataTypes) {
  const { loanBorrowerType } = useAppSelector((state) => state.loans);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState('');
  const handleChangeType = (value: string) => {
    dispatch(setLoanBorrowerType(value));
    setFieldValue('loanBorrowerType', value);
    // @ts-ignore
    setValue(value);
  };

  useEffect(() => {
    setValue(loanBorrowerType);
  }, [currentStepIndex, loanBorrowerType]);

  return (
    <FormWrapper>
      <div className="loans__selectLoanTypeBorrowerForm">
        <div className="loans__selectLoanTypeBorrowerForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">
              What&lsquo;s your relationship with the borrower?
            </h2>
            <div className="loanTypeForm__subtitle">
              We&lsquo;ll use this info to manage and organize your loan.
            </div>
          </div>
          <div className="loans__selectLoanTypeBorrowerForm__body">
            {/* eslint-disable-next-line */}
            <div
              className={classNames(
                'loans__selectLoanTypeBorrowerForm__typeSelect',
                value === LoanBorrowerType.EMPLOYEE
                  ? 'loans__selectLoanTypeBorrowerForm__typeSelect--active'
                  : '',
              )}
              onClick={() => handleChangeType(LoanBorrowerType.EMPLOYEE)}
            >
              <div className="loans__selectLoanTypeBorrowerForm__typeSelect__descr">
                <img
                  className="loans__selectLoanTypeBorrowerForm__typeSelect__image"
                  src={employee}
                  alt="employee"
                />
                <div className="loans__selectLoanTypeBorrowerForm__typeSelect__title">
                  Employee / Team Member
                </div>
              </div>
            </div>
            {/* eslint-disable-next-line */}
            <div
              className={classNames(
                'loans__selectLoanTypeBorrowerForm__typeSelect',
                value === LoanBorrowerType.BUSINESS_ENTITY
                  ? 'loans__selectLoanTypeBorrowerForm__typeSelect--active'
                  : '',
              )}
              onClick={() => handleChangeType(LoanBorrowerType.BUSINESS_ENTITY)}
            >
              <div className="loans__selectLoanTypeBorrowerForm__typeSelect__descr">
                <img
                  className="loans__selectLoanTypeBorrowerForm__typeSelect__image"
                  src={businessEntity}
                  alt="businessEntity"
                />
                <div className="loans__selectLoanTypeBorrowerForm__typeSelect__title">
                  Business Entity
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
