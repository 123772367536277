import { useHistory } from 'react-router-dom';

import { useAppSelector } from '../../../main/store/hooks';
import Button from '../../../shared/components/Button';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import OverviewMetrics from '../components/OverView/OverviewMetrics';
import RecentNews from '../components/OverView/RecentNews';

export default function LoanOverviewPage() {
  const { partner } = useAppSelector((state) => state.auth);
  const history = useHistory();

  return (
    <DashboardLayout>
      {partner && (
        <>
          <div className="loans-overview__title-wrapper">
            <h2 className="main-title">Overview</h2>
            <Button
              className="button button--primary-blue button--lg loans-overview__create-button"
              icon="plus"
              onClick={() => {
                history.push('/loan-creation');
              }}
            >
              Create loan
            </Button>
          </div>

          <OverviewMetrics />

          <RecentNews />
        </>
      )}
    </DashboardLayout>
  );
}
