import cn from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import Button from '../../../../shared/components/Button';
import { defaultRealm } from '../../../../shared/constants/DefaultRealm';
import useMultistepForm from '../../../../shared/hooks/useMultistepForm';
import { FundingSourceModel } from '../../../../shared/models/FundingSource';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import { formatDollarAmount } from '../../../../shared/utils';
import { getPaymentMethods } from '../../../finances/store/actions';
import Progress from '../../../init-setup/components/Progress/Progress';
import {
  LoanBorrowerType,
  setCreatedLoanIsLoading,
  setLoanBorrowerType,
  setLoanBusinessType,
  setLoanPaymentType,
  setLoanReasonType,
} from '../../store';
import { LoanSubmissionRequest, loanSubmissionApi } from '../../store/actions/loanSubmissionApi';
import {
  borrowerSchema,
  createLoanAmountSchema,
  loanBorrowerTypeSchema,
  loanBusinessTypeSchema,
  loanPaymentTypeSchema,
  loanReasonTypeSchema,
  loanTypeSchema,
} from '../FormSchema/index';
import LoanSummaryForm from '../LoanSummaryForm/LoanSummaryForm';
import LoanTypeForm from '../LoanTypeForm/LoanTypeForm';
import SelectAmountForm from '../SelectAmountForm/SelectAmountForm';
import SelectBorrowerForm from '../SelectBorrowerForm/SelectBorrowerForm';
import SelectBorrowerTypeForm from '../SelectBorrowerTypeForm/SelectBorrowerTypeForm';
import SelectBusinessTypeForm from '../SelectBusinessTypeForm/SelectBusinessTypeForm';
import SelectReasonTypeForm from '../SelectReasonTypeForm/SelectReasonTypeForm';
import SelectRepaymentPlanForm from '../SelectRepaymentPlanForm/SelectRepaymentPlanForm';

export type FormData = {
  loanType: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  loanBorrowerType: string;
  loanBusinessType: string;
  loanAmount: number | undefined;
  loanServiceFeeAmount: number;
  loanCoverageAmount: number;
  loanTotalFeeAmount: number;
  loanTotalAmount: number;
  loanReasonType: string | undefined;
  loanPaymentType: string;
  loanRepaymentDate?: Date;
};

const INITIAL_DATA: FormData = {
  loanType: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  loanBorrowerType: '',
  loanBusinessType: '',
  loanAmount: undefined,
  loanServiceFeeAmount: 0,
  loanCoverageAmount: 0,
  loanTotalFeeAmount: 0,
  loanTotalAmount: 0,
  loanReasonType: '',
  loanPaymentType: '',
};

export default function LoanCreationForm() {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { loanType, loanBorrowerType, paymentScheduleDetailed, limitations } = useAppSelector(
    (state) => state.loans,
  );
  const { paymentMethods, loading } = useAppSelector((state) => state.finances, shallowEqual);

  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const loanContainerRef = useRef<HTMLDivElement>(null);

  const loanAmountSchema = createLoanAmountSchema(
    limitations.minLoanAmount,
    limitations.maxLoanAmount,
  );

  useEffect(() => {
    // @ts-ignore
    dispatch(getPaymentMethods());
  }, []);

  function currentValidation(index: number) {
    const schemas = [
      loanTypeSchema,
      borrowerSchema,
      loanBorrowerTypeSchema,
      loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY
        ? loanBusinessTypeSchema
        : loanAmountSchema,
      loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY
        ? loanAmountSchema
        : loanReasonTypeSchema,
      loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY
        ? loanReasonTypeSchema
        : loanPaymentTypeSchema,
      loanPaymentTypeSchema,
    ];

    return schemas[index] || null;
  }

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isValid,
    validateForm,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    setErrors,
    setFieldError,
    setTouched,
  } = useFormik({
    initialValues: INITIAL_DATA,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: currentValidation(currentStepIndex),
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubmit: onSubmitHandler,
  });

  const propsData = {
    values,
    handleChange,
    handleBlur,
    validateField,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    resetForm,
  };

  const [loanNextButtonEnabled, setLoanNextButtonEnabled] = useState(false);

  const steps = useMemo(() => {
    const baseProps = { currentStepIndex, ...propsData };

    const borrowerTypeSteps =
      loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY
        ? [
            <SelectBusinessTypeForm
              key="SelectBusinessTypeForm"
              setFieldError={setFieldError}
              {...baseProps}
            />,
            <SelectAmountForm
              key="SelectAmountForm1"
              {...baseProps}
              validateField={validateField}
            />,
            <SelectReasonTypeForm
              key="SelectReasonTypeForm1"
              setFieldError={setFieldError}
              {...baseProps}
            />,
            // @ts-ignore
            <SelectRepaymentPlanForm
              key="SelectRepaymentPlanForm1"
              loanContainerRef={loanContainerRef}
              setLoanNextButtonEnabled={setLoanNextButtonEnabled}
              {...baseProps}
            />,
            <LoanSummaryForm key="LoanSummaryForm" {...baseProps} />,
          ]
        : [
            <SelectAmountForm
              key="SelectAmountForm2"
              {...baseProps}
              validateField={validateField}
            />,
            <SelectReasonTypeForm
              key="SelectReasonTypeForm2"
              setFieldError={setFieldError}
              {...baseProps}
            />,
            // @ts-ignore
            <SelectRepaymentPlanForm
              key="SelectRepaymentPlanForm2"
              loanContainerRef={loanContainerRef}
              setLoanNextButtonEnabled={setLoanNextButtonEnabled}
              {...baseProps}
            />,
            <LoanSummaryForm key="LoanSummaryForm" {...baseProps} />,
          ];

    return [
      <LoanTypeForm key="LoanTypeForm" {...baseProps} />,
      <SelectBorrowerForm key="SelectBorrowerForm" {...baseProps} />,
      <SelectBorrowerTypeForm key="SelectBorrowerTypeForm" {...baseProps} />,
      ...borrowerTypeSteps,
    ];
  }, [
    currentStepIndex,
    loanBorrowerType,
    propsData,
    validateField,
    loanContainerRef,
    setLoanNextButtonEnabled,
  ]);

  const { step, back, next, isFirstStep, isLastStep } = useMultistepForm({
    steps,
    currentStepIndex,
    setCurrentStepIndex,
  });

  const isLastStepForm =
    (loanBorrowerType === LoanBorrowerType.EMPLOYEE && currentStepIndex === 6) ||
    (loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY && currentStepIndex === 7);

  async function createOffer(data: LoanSubmissionRequest) {
    try {
      // @ts-ignore
      const loanInfo = await dispatch(loanSubmissionApi(data));

      dispatch(setCreatedLoanIsLoading(true));
      // @ts-ignore
      history.push(`/loan-details/${loanInfo?.payload?.uuid}`);
    } catch (error) {
      dispatch(setCreatedLoanIsLoading(false));
    }
  }

  // eslint-disable-next-line
  async function onSubmitHandler(value: FormData, event: any) {
    if (isValid && !isLastStepForm) {
      next();
    }

    if (event.key === 'Enter') {
      next();
    }

    if (isLastStepForm) {
      const activePaymentMethod = paymentMethods.find((method) => method.isActive) || {
        uuid: '',
        realm: '',
      };

      const data: LoanSubmissionRequest = {
        scheduleJwt: paymentScheduleDetailed.scheduleJwt,
        isLendLoan: true,
        lenderType: 'partner',
        borrowerType: loanBorrowerType === LoanBorrowerType.EMPLOYEE ? 'user' : 'business_user',
        message: '',
        reason: values.loanReasonType,
        relationship: '',
        initialTargetName: `${values.firstName} ${values.lastName}`,
        mediaUrl: '',
        targetContactUri: `tel:+1${values.phone.replace(/[^\d]/g, '')}`,
        isRepaymentRequest: false,
        isCashPickupEnabled: false,
        partnerDeeplinkId: '',
        repaymentTarget: {
          realm: defaultRealm,
          accountId: activePaymentMethod.uuid,
        },
        disbursementSource: {
          realm: defaultRealm,
          accountId: activePaymentMethod.uuid,
        },
        repaymentSource: undefined,
        disbursementTarget: undefined,
      };
      createOffer(data);
    }
  }

  function handleBlack() {
    if (isFirstStep) {
      validateForm();
    }

    back();

    if (currentStepIndex === 1) {
      resetForm();
      setFieldValue('loanType', loanType);
      dispatch(setLoanBorrowerType(''));
      dispatch(setLoanBusinessType(undefined));
      setFieldValue('loanAmount', undefined);
      setFieldValue('loanServiceFeeAmount', 0);
      setFieldValue('loanCoverageAmount', 0);
      setFieldValue('loanTotalFeeAmount', 0);
      setFieldValue('loanTotalAmount', 0);
      dispatch(setLoanReasonType(undefined));
      dispatch(setLoanPaymentType(''));
    }
  }

  const isButtonDisabled = useMemo(() => {
    const isInvalidOrMissingData = () =>
      !values.firstName || !values.lastName || !values.phone || !values.email || !isValid;

    if (isFirstStep && !values.loanType) {
      return true;
    }

    if (isLastStepForm && loading) {
      return true;
    }

    switch (currentStepIndex) {
      case 1:
        return isInvalidOrMissingData();

      case 2:
        return !values.loanBorrowerType || !isValid;

      case 3:
        return (
          (loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY && !values.loanBusinessType) ||
          (loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY &&
            values.loanBusinessType === 'other-custom') ||
          (loanBorrowerType === LoanBorrowerType.EMPLOYEE && !values.loanAmount) ||
          !isValid
        );

      case 4:
        return (
          (loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY && !values.loanAmount) ||
          (loanBorrowerType === LoanBorrowerType.EMPLOYEE && !values.loanReasonType) ||
          (loanBorrowerType === LoanBorrowerType.EMPLOYEE &&
            values.loanReasonType === 'other-custom') ||
          !isValid
        );

      case 5:
        return (
          (loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY &&
            !values.loanReasonType &&
            !isValid) ||
          (loanBorrowerType === LoanBorrowerType.EMPLOYEE && !loanNextButtonEnabled)
        );

      case 6:
        return loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY && !loanNextButtonEnabled;

      default:
        return false;
    }
  }, [
    isFirstStep,
    currentStepIndex,
    values,
    isValid,
    loanNextButtonEnabled,
    loanBorrowerType,
    isLastStepForm,
    loading,
  ]);

  const totalSteps = 12;

  const currentProgressStep = useMemo(() => {
    type StepMapping = { [key: number]: number };
    const isInvalidOrMissingData = () =>
      values.firstName && values.lastName && values.phone && values.email && isValid;

    const stepMapping: StepMapping = {
      0: 1,
      1: isInvalidOrMissingData() ? 2 : 1,
      2: values.loanBorrowerType && isValid ? 4 : 3,
      3:
        loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY
          ? values.loanBusinessType && isValid
            ? 6
            : 5
          : values.loanAmount && isValid
          ? 6
          : 5,
      4:
        loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY
          ? values.loanAmount && isValid
            ? 8
            : 7
          : values.loanReasonType && isValid
          ? 8
          : 7,
      5:
        loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY
          ? values.loanReasonType && isValid
            ? 10
            : 9
          : loanNextButtonEnabled
          ? 11
          : 9,
      6: loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY && loanNextButtonEnabled ? 11 : 10,
    };

    return isLastStep ? totalSteps : stepMapping[currentStepIndex] || 1;
  }, [currentStepIndex, values, isValid, loanNextButtonEnabled, loanBorrowerType]);

  const onClickSubmitButtonHandler = () => {
    const steps =
      loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY ? [0, 2, 3, 5, 6] : [0, 2, 4, 5];

    const isBankAccount = paymentMethods.length > 0;
    const isBankAccountVerified = paymentMethods.some((item) =>
      FundingSourceModel.isVerified(item),
    );

    if (
      loanBorrowerType === LoanBorrowerType.BUSINESS_ENTITY &&
      currentStepIndex === 3 &&
      values.loanBusinessType === 'other-custom'
    ) {
      setFieldError('loanBusinessType', 'Please select a business type');
      setFieldTouched('loanBusinessType', true);
      return;
    }
    if (
      loanBorrowerType === LoanBorrowerType.EMPLOYEE &&
      currentStepIndex === 4 &&
      values.loanReasonType === 'other-custom'
    ) {
      setFieldError('loanReasonType', 'Please select a business type');
      setFieldTouched('loanReasonType', true);
      return;
    }
    if (isButtonDisabled && steps.includes(currentStepIndex)) {
      dispatch(
        addAlert({
          text: 'Please make a selection to continue.',
          type: AlertType.dismiss,
        }),
      );
    } else if (!isBankAccount) {
      dispatch(
        addAlert({
          text: `Please add a bank account in "Bank" section.`,
          type: AlertType.dismiss,
        }),
      );
    } else if (!isBankAccountVerified) {
      dispatch(
        addAlert({
          text: `Please verify the bank account in "Bank" section.`,
          type: AlertType.dismiss,
        }),
      );
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrors({});
      setTouched({});
    }, 0);
  }, [currentStepIndex]);

  useEffect(
    () => () => {
      resetForm();
    },
    [],
  );

  return (
    <>
      <form onSubmit={handleSubmit} className="loans__multiStepForm__form">
        <div
          className="multiStepForm__step loans__multiStepForm__form__container"
          ref={loanContainerRef}
        >
          {step}
        </div>
        <div style={{ height: '8px' }}>
          {currentStepIndex > 0 && (
            <Progress
              currentStep={currentProgressStep}
              totalSteps={totalSteps}
              classes="loan-create-progress"
            />
          )}
        </div>
        <div
          className={cn(
            'loans__footer',
            currentStepIndex > 0 ? 'loans__footer--noBorder' : '',
            isLastStepForm ? 'loans__footer--lastStep' : '',
          )}
        >
          <div className="loans__footer__left">
            {isLastStepForm ? (
              <button type="button" className="loans__button--prev" onClick={handleBlack}>
                Previous
              </button>
            ) : (
              ''
            )}
          </div>
          <div
            className={cn(
              'loans__footer__right',
              isLastStepForm ? 'loans__footer__right--lastStep' : '',
            )}
          >
            {isFirstStep || isLastStepForm ? (
              ''
            ) : (
              <button type="button" className="loans__button--prev" onClick={handleBlack}>
                Previous
              </button>
            )}
            {isLastStepForm ? (
              <div className="loans__footer__totalAmount">
                <div className="loans__footer__totalAmount__label">You’re offering</div>
                <div className="loans__footer__totalAmount__value">
                  {formatDollarAmount(values.loanTotalAmount)}
                </div>
              </div>
            ) : (
              ''
            )}
            <Button
              ref={nextButtonRef}
              className={`button--primary-blue loans__button ${
                isButtonDisabled && 'button--like-disabled'
              }`}
              onClick={onClickSubmitButtonHandler}
            >
              {isLastStepForm ? 'Create offer' : 'Next'}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
