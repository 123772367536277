import cn from 'classnames';
import { addMonths } from 'date-fns';
import { FormikErrors, FormikTouched } from 'formik';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { animated, useSpring } from 'react-spring';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import Button from '../../../../shared/components/Button';
import { formatCurrency } from '../../../../shared/utils';
import { FormWrapper } from '../../../init-setup/components';
import monthly from '../../assets/monthly.svg';
import oneTime from '../../assets/one-time.svg';
import {
  PaymentScheduleItemDto,
  PlanType,
  setLoanPaymentType,
  setLoanRepaymentDate,
  setLoanRepaymentMonths,
  setLoanStartDate,
} from '../../store';
import { scheduleApi } from '../../store/actions/scheduleApi';
import RepaymentDateInput from '../RepaymentDateInput/RepaymentDateInput';
import { RepaymentMonthSlider } from '../RepaymentMonthSlider/RepaymentMonthSlider';

type FormData = {
  loanAmount: number | undefined;
  loanTotalAmount: number;
  loanPaymentType: string;
  loanRepaymentDate: string;
};

type FormicDataTypes = {
  loanContainerRef: RefObject<HTMLDivElement>;
  setLoanNextButtonEnabled: Dispatch<SetStateAction<boolean>>;
  currentStepIndex: number;
  values: FormData;
  errors: FormikErrors<FormData>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

export default function SelectRepaymentPlanForm({
  loanContainerRef,
  setLoanNextButtonEnabled,
  currentStepIndex,
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
}: FormicDataTypes) {
  const dispatch = useAppDispatch();

  const {
    loanPaymentType,
    loanRepaymentDate,
    loanStartDate,
    loanRepaymentMonths,
    paymentScheduleDetailed,
    limitations,
  } = useAppSelector((state) => state.loans);

  const [repaymentCadence, setRepaymentCadence] = useState<PlanType | string>('');
  const [showAllTerms, setShowAllTerms] = useState(false);
  const [contentHeight, setContentHeight] = useState(600);
  const contentRef = useRef<HTMLDivElement>(null);

  const onCardClick = (cadence: PlanType) => {
    dispatch(setLoanPaymentType(cadence));
    setFieldValue('loanPaymentType', cadence);

    if (cadence === PlanType.MONTHLY) {
      dispatch(setLoanRepaymentMonths(limitations.monthlyPayback.min));
      dispatch(setLoanStartDate(addMonths(new Date(), 1).toString()));
      setFieldValue('loanRepaymentDate', '');
      dispatch(setLoanRepaymentDate(''));
    } else {
      dispatch(setLoanRepaymentMonths(1));
      dispatch(setLoanStartDate(''));
      setShowAllTerms(false);
    }
    setRepaymentCadence(cadence);
  };

  const minDate = moment().add(limitations.onetimePayback.min, 'month').add(0, 'd');
  const maxDate = moment().add(limitations.onetimePayback.max, 'month').add(0, 'd');

  const animation = useSpring({
    // eslint-disable-next-line
    height: showAllTerms
      ? `${contentHeight + 10}px`
      : repaymentCadence === PlanType.ONE_TIME // eslint-disable-line
      ? '224px'
      : paymentScheduleDetailed.payments.length === 2 // eslint-disable-line
      ? '350px'
      : paymentScheduleDetailed.payments.length === 3
      ? '450px'
      : '520px',
    overflow: 'hidden',
    config: { tension: 320, friction: 40 },
  });

  const updateSize = () => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [showAllTerms, window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if (
      !repaymentCadence ||
      (repaymentCadence === PlanType.MONTHLY && !loanRepaymentMonths) ||
      (repaymentCadence === PlanType.ONE_TIME && !loanStartDate) ||
      !paymentScheduleDetailed.payments.length
    ) {
      setLoanNextButtonEnabled(false);
    } else {
      setLoanNextButtonEnabled(true);
    }
  }, [
    repaymentCadence,
    loanRepaymentMonths,
    loanStartDate,
    values.loanTotalAmount,
    currentStepIndex,
    paymentScheduleDetailed,
  ]);

  const handleScheduleApiCall = useCallback(
    debounce(
      ({
        type,
        amount,
        currency,
        numOfPayments,
        oneTimeDueDays,
        isBillPay,
        isRepaymentRequest,
      }) => {
        dispatch(
          // @ts-ignore
          scheduleApi({
            type,
            amount,
            currency,
            numOfPayments,
            oneTimeDueDays,
            isBillPay,
            isRepaymentRequest,
          }),
        );
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    const oneTimeDueDays = moment(values.loanRepaymentDate).diff(moment(), 'd') + 1;

    handleScheduleApiCall({
      type: repaymentCadence,
      amount: values.loanAmount,
      currency: 'USD',
      numOfPayments: repaymentCadence === PlanType.MONTHLY ? loanRepaymentMonths : undefined,
      oneTimeDueDays: repaymentCadence === PlanType.ONE_TIME ? oneTimeDueDays : undefined,
      isBillPay: false,
      isRepaymentRequest: false,
    });
  }, [
    values.loanAmount,
    values.loanRepaymentDate,
    repaymentCadence,
    loanRepaymentMonths,
    handleScheduleApiCall,
  ]);

  const RepaymentPlan = (
    modifiedLoanTotalAmount: number,
    modifiedRepaymentMonths: number,
    modifiedPaymentTerms: PaymentScheduleItemDto[],
  ) => {
    const isOneTime = modifiedRepaymentMonths === 1;

    return (
      <animated.div
        style={animation}
        className="loans__selectLoanRepaymentPlanForm__planBlock"
        ref={contentRef}
      >
        <div className="loans__selectLoanRepaymentPlanForm__planBlock__row">
          <div className="loans__selectLoanRepaymentPlanForm__planBlock__label">Repayment plan</div>
          <div className="loans__selectLoanRepaymentPlanForm__planBlock__value">
            {isOneTime ? 'One-time' : `${modifiedRepaymentMonths} months`}
          </div>
        </div>
        <div className="loans__selectLoanRepaymentPlanForm__planBlock__divider" />
        <div className="loans__selectLoanRepaymentPlanForm__planBlock__row">
          <div className="loans__selectLoanRepaymentPlanForm__planBlock__label">Total to repay</div>
          <div className="loans__selectLoanRepaymentPlanForm__planBlock__value">
            {isOneTime
              ? formatCurrency(modifiedPaymentTerms[0].amount)
              : formatCurrency(modifiedLoanTotalAmount)}
          </div>
        </div>
        <div className="loans__selectLoanRepaymentPlanForm__planBlock__divider" />
        {modifiedPaymentTerms
          .slice(0, showAllTerms ? modifiedPaymentTerms.length : 3)
          .map((term, index) => (
            <>
              <div key={index} className="loans__selectLoanRepaymentPlanForm__planBlock__row">
                <div className="loans__selectLoanRepaymentPlanForm__planBlock__label">
                  {term.label}
                  {!isOneTime && (
                    <span className="loans__selectLoanRepaymentPlanForm__planBlock__date">
                      {term.date}
                    </span>
                  )}
                </div>
                <div className="loans__selectLoanRepaymentPlanForm__planBlock__value">
                  {isOneTime ? term.date : formatCurrency(term.amount)}
                </div>
              </div>
              <div
                className={cn(
                  'loans__selectLoanRepaymentPlanForm__planBlock__divider',
                  modifiedPaymentTerms.length === index + 1
                    ? 'loans__selectLoanRepaymentPlanForm__planBlock__divider__hide'
                    : '',
                )}
              />
            </>
          ))}
        {paymentScheduleDetailed.payments.length > 3 && (
          <Button
            type="button"
            className="button button--secondary-blue button--lg loans__selectLoanRepaymentPlanForm__button"
            onClick={() => setShowAllTerms(!showAllTerms)}
          >
            {showAllTerms ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </animated.div>
    );
  };

  useEffect(() => {
    setRepaymentCadence(loanPaymentType);
    if (loanRepaymentDate) setFieldValue('loanRepaymentDate', loanRepaymentDate);
  }, [currentStepIndex, loanPaymentType]);

  return (
    <FormWrapper>
      <div className="loans__selectLoanRepaymentPlanForm">
        <div className="loans__selectLoanRepaymentPlanForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">How would you like to be repaid?</h2>
          </div>
          <div className="loans__selectLoanRepaymentPlanForm__body">
            <div className="loans__selectLoanRepaymentPlanForm__buttons">
              <button
                type="button"
                className={cn(
                  'loans__selectLoanRepaymentPlanForm__typeSelect',
                  repaymentCadence === PlanType.MONTHLY
                    ? 'loans__selectLoanRepaymentPlanForm__typeSelect--active'
                    : '',
                )}
                onClick={() => onCardClick(PlanType.MONTHLY)}
              >
                <div className="loans__selectLoanRepaymentPlanForm__typeSelect__descr">
                  <img
                    className="loans__selectLoanRepaymentPlanForm__typeSelect__image"
                    src={monthly}
                    alt="monthly"
                  />
                  <div className="loans__selectLoanRepaymentPlanForm__typeSelect__title">
                    Monthly
                  </div>
                </div>
              </button>
              <button
                type="button"
                className={cn(
                  'loans__selectLoanRepaymentPlanForm__typeSelect',
                  repaymentCadence === PlanType.ONE_TIME
                    ? 'loans__selectLoanRepaymentPlanForm__typeSelect--active'
                    : '',
                )}
                onClick={() => onCardClick(PlanType.ONE_TIME)}
              >
                <div className="loans__selectLoanRepaymentPlanForm__typeSelect__descr">
                  <img
                    className="loans__selectLoanRepaymentPlanForm__typeSelect__image"
                    src={oneTime}
                    alt="oneTime"
                  />
                  <div className="loans__selectLoanRepaymentPlanForm__typeSelect__title">
                    One-time
                  </div>
                </div>
              </button>
            </div>

            {repaymentCadence && (
              <div className="loans__selectLoanRepaymentPlanForm__info">
                We’ll take care of the reminders and automated <br />
                repayments, so you can focus on your relationship instead.
              </div>
            )}

            {repaymentCadence === PlanType.MONTHLY && (
              <div>
                <h2 className="loanTypeForm__title">I want to be repaid within...</h2>
                <RepaymentMonthSlider setShowAllTerms={setShowAllTerms} />
              </div>
            )}

            {repaymentCadence === PlanType.ONE_TIME && (
              <div>
                <h2 className="loanTypeForm__title">When do you want to receive the payment?</h2>
                <div className="loans__selectLoanRepaymentPlanForm__datePicker">
                  <RepaymentDateInput
                    loanContainerRef={loanContainerRef}
                    id="repaymentDate"
                    value={values.loanRepaymentDate}
                    onChange={(date) => {
                      dispatch(setLoanStartDate(date?.toString() || ''));
                      setFieldValue('loanRepaymentDate', date?.toString() || '');
                      dispatch(setLoanRepaymentDate(date?.toString() || ''));
                    }}
                    dateMask="MM/dd/yyyy"
                    minDate={minDate.toDate()}
                    maxDate={maxDate.toDate()}
                    iconName="icon-time"
                    useCurrentYearAsStart
                  />
                </div>
              </div>
            )}

            {loanStartDate && paymentScheduleDetailed.payments.length
              ? RepaymentPlan(
                  values.loanTotalAmount,
                  loanRepaymentMonths,
                  showAllTerms
                    ? paymentScheduleDetailed.payments
                    : paymentScheduleDetailed.payments.slice(0, 3),
                )
              : ''}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
