import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DashBoardSliceState = {
  menuOpen: boolean;
  showIntercom: boolean;
  showBanner: boolean;
  toolkitMenuOpen: boolean;
  openNavBar: boolean;
};

const initialState: DashBoardSliceState = {
  menuOpen: false,
  showIntercom: false,
  showBanner: true,
  toolkitMenuOpen: true,
  openNavBar: true,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState as DashBoardSliceState,
  reducers: {
    setMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.menuOpen = action.payload;
    },
    setShowIntercom: (state, action: PayloadAction<boolean>) => {
      state.showIntercom = action.payload;
    },
    setShowBanner: (state, action: PayloadAction<boolean>) => {
      state.showBanner = action.payload;
    },
    setToolkitMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.toolkitMenuOpen = action.payload;
    },
    setOpenNavBar: (state, action: PayloadAction<boolean>) => {
      state.openNavBar = action.payload;
    },
  },
});

export default dashboardSlice;
export const { reducer: dashboardReducer } = dashboardSlice;
export const { setMenuOpen, setShowIntercom, setShowBanner, setToolkitMenuOpen, setOpenNavBar } =
  dashboardSlice.actions;
