import classNames from 'classnames';
import { ReactNode, FC, useEffect, useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../main/store/hooks';
import { Alert, Icon } from '../../../shared';
import { FlowTypes, setToggleGetHelpPanel } from '../../auth/store';
import DevToolsMenu from '../../developer-tools/components/DevToolsMenu';
import SelectionFlowSteps from '../../init-setup/components/SelectionFlow/SelectionFlowSteps';
import Banner from '../components/Banner';
import DashboardHeader from '../components/DashboardHeader';
import DashboardNav from '../components/DashboardNav';
import MobileMenu from '../components/MobileMenu';
import { setToolkitMenuOpen, setOpenNavBar } from '../store/dashboardSlice';

const DashboardLayout: FC<{
  children?: ReactNode;
  pageTitle?: string;
  isRounded?: boolean;
}> = ({ children, pageTitle, isRounded = false }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pathNameParts = location.pathname.split('/')[1];
  const {
    partner,
    isPartnerLoading,
    finishedSetup,
    currentStepSetup,
    selectedFlow,
    toggleGetHelpPanel,
  } = useAppSelector((store) => store.auth, shallowEqual);
  const isBannerShown = useAppSelector((store) => store.dashboard.showBanner);
  const { toolkitMenuOpen, openNavBar } = useAppSelector((state) => state.dashboard);

  const handleNavBar = () => {
    dispatch(setOpenNavBar(!openNavBar));
    // TODO remove comment. Remove condition after merge prod
    // dispatch(setToolkitMenuOpen(!openNavBar));
    if (location.pathname.includes('/developer-tools/webhooks/configuration')) {
      dispatch(setToolkitMenuOpen(!openNavBar));
    }
  };

  useEffect(() => {
    if (pathNameParts !== 'developer-tools') {
      dispatch(setToolkitMenuOpen(false));
    }
  }, [pathNameParts, dispatch]);

  const getClass = useCallback(() => {
    let classes = '';
    if (toolkitMenuOpen && openNavBar) {
      classes = 'dashboard__main dashboard__main--openToolsMenu';
    } else if ((openNavBar === false && toolkitMenuOpen) || openNavBar) {
      classes = 'dashboard__main dashboard__main--openNavBar';
    } else {
      classes = 'dashboard__main';
    }
    return classes;
  }, [toolkitMenuOpen, openNavBar]);

  // eslint-disable-next-line
  const getCurrentStepNumber = () => {
    if (selectedFlow === FlowTypes.AFFILIATE_PARTNER) {
      if (currentStepSetup === 1) {
        return 'Step 1';
      }
      if (currentStepSetup === 2) {
        return 'Step 2';
      }
    } else {
      if (currentStepSetup === 1) {
        return 'Step 1';
      }
      if (currentStepSetup === 2) {
        return 'Step 2';
      }
      if (currentStepSetup === 3) {
        return 'Step 3';
      }
      if (currentStepSetup === 4) {
        return 'Step 4';
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const getCurrentStepName = () => {
    if (selectedFlow === FlowTypes.AFFILIATE_PARTNER) {
      if (currentStepSetup === 1) {
        return 'Get started';
      }
      if (currentStepSetup === 2) {
        return 'Launch';
      }
    } else {
      if (currentStepSetup === 1) {
        return 'Get started';
      }
      if (currentStepSetup === 2) {
        return 'Business information';
      }
      if (currentStepSetup === 3) {
        return 'Bank account';
      }
      if (currentStepSetup === 4) {
        return 'Launch';
      }
    }
  };

  return (
    <>
      <section className="dashboard">
        <DashboardHeader />
        {isPartnerLoading ? (
          <>
            {!finishedSetup ? (
              <div className="dashboard__init-block">
                {currentStepSetup === 1 ? '' : <SelectionFlowSteps />}
                <div className="dashboard__init-block__step">{getCurrentStepNumber()}</div>
                <div className="dashboard__init-block__title">{getCurrentStepName()}</div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        <div className="dashboard__box">
          {isPartnerLoading ? (
            <>
              {!finishedSetup ? (
                ''
              ) : (
                <DashboardNav
                  handleNavBar={handleNavBar}
                  openNavBar={openNavBar}
                  toolkitMenuOpen={toolkitMenuOpen}
                />
              )}
            </>
          ) : (
            ''
          )}
          <DevToolsMenu openNavBar={openNavBar} toolkitMenuOpen={toolkitMenuOpen} />
          <div
            className={`${
              isPartnerLoading && !finishedSetup
                ? `dashboard__main dashboard__main--openNavBar__init ${
                    currentStepSetup === 1 ? 'dashboard__main--openNavBar__init__first' : ''
                  }`
                : getClass()
            }`}
          >
            {partner &&
              isBannerShown &&
              finishedSetup &&
              partner.details.flowType === FlowTypes.BILL_PAY && <Banner />}
            <div
              className={classNames(
                'dashboard__content',
                {
                  finishedSetup,
                },
                `${isPartnerLoading && !finishedSetup ? 'dashboard__content__init' : ''}`,
                `${
                  location.pathname === '/loan-creation' ? 'dashboard__content__loanCreation' : ''
                }`,
                `${isRounded ? 'dashboard__content__loanCreation' : ''}`,
                location.pathname.includes('loan')
                  ? 'dashboard__content__loanCreation--isBannerClosed'
                  : '',
              )}
            >
              {children}
            </div>
          </div>
          {finishedSetup ? (
            ''
          ) : (
            <div className={`dashboard__helpPanel ${toggleGetHelpPanel ? 'open' : ''}`}>
              <div className="dashboard__helpPanel__header">
                Help panel
                {/* eslint-disable-next-line */}
                <img
                  src="images/dashboard/angle-right-solid.svg"
                  alt="angle-right-solid"
                  onClick={() => dispatch(setToggleGetHelpPanel(!toggleGetHelpPanel))}
                />
              </div>
              <div className="dashboard__helpPanel__body">
                <div className="dashboard__helpPanel__descr">
                  Looking for more? We&apos;re here to help and happy to explore other options that
                  may better suit your needs.
                </div>
                <div className="dashboard__helpPanel__subtitle">Got questions?</div>
                <div className="dashboard__helpPanel__descr">
                  {/* eslint-disable-next-line */}
                  Please reach out to us via{' '}
                  <a href="mailto: partners@zirtue.com">partners@zirtue.com</a> if you have any
                  questions.
                </div>
                <div className="dashboard__helpPanel__divider" />
                <div className="dashboard__helpPanel__subtitle">
                  Learn more
                  {/* eslint-disable-next-line */}
                  <div
                    className="dashboard__helpPanel__iconLink"
                    onClick={() => {
                      window.open(`https://docs.zirtue.com/docs/partner-portal`, '_blank');
                    }}
                  >
                    <Icon name="external-link-alt-solid-black" />
                  </div>
                </div>
                <a
                  className="dashboard__helpPanel__link"
                  target="_blank"
                  href="https://docs.zirtue.com/docs/partner-portal"
                  rel="noreferrer"
                >
                  Zirtue Docs
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
      <MobileMenu />
      <Alert position="top" />
    </>
  );
};

export default DashboardLayout;
