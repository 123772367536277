import { startOfDay } from 'date-fns';

import { DateRangeTuple } from '../types';

export const getTimePeriod = (days: number): DateRangeTuple => {
  const today = new Date();
  let oldDate = new Date();

  if (days === 1) {
    oldDate = startOfDay(today);
  } else {
    oldDate.setDate(today.getDate() - days);
  }

  return [oldDate.toISOString(), today.toISOString()];
};
