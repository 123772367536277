import { useCallback } from 'react';

import AnalyticsSection from './AnalyticsSection';
import DayTab, { Days } from './DayTab';
import Filters from './Filters';
import LineChart from './LineChart';
import Notification from './Notification';
import PieChart from './PieChart';
import StateCard, { Type } from './StateCard';

export default function OverviewMetrics() {
  const handlePieDays = useCallback((day: Days) => {
    // it's okay :)
    console.log(day);
  }, []);

  return (
    <div style={{ marginBottom: 24 }} className="card card--shadow">
      <Notification />
      <Filters />
      <section className="loans-overview__metric-section">
        <div style={{ padding: '24px 16px 0 26px' }} className="card">
          <h3 className="loans-overview__metric-section__title">Reasons for loans</h3>
          <DayTab onChange={handlePieDays} />
          <PieChart />
        </div>

        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 24,
              marginBottom: 41,
              minWidth: 360,
              height: 182,
            }}
            className="card"
          >
            <AnalyticsSection title="Total loans created" value={316} percent={25} />
            <LineChart />
          </div>
          <div style={{ padding: '24px 21px' }} className="card">
            <h4 className="loans-overview__metric-section__title-secondary">Total loans repaid</h4>
            <div style={{ display: 'flex' }}>
              <span className="loans-overview__metric-section__small-section-value">78</span>
              <div className="loans-overview__metric-section__percent-container">
                <div className="triangle" />
                <div className="loans-overview__metric-section__percent-value">6%</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 24,
              marginBottom: 41,
              minWidth: 360,
              height: 182,
            }}
            className="card"
          >
            <AnalyticsSection title="Total loans accepted" value={124} percent={12} />
            <LineChart />
          </div>
          <div style={{ padding: '24px 21px' }} className="card">
            <h4 className="loans-overview__metric-section__title-secondary">Delinquency rate</h4>
            <span className="loans-overview__metric-section__small-section-value">0%</span>
          </div>
        </div>
      </section>
    </div>
  );
}
