import { useState } from 'react';

export enum Days {
  sevendays = '7 days',
  thirtydays = '30 days',
  sixtydays = '60 days',
}

const days = [Days.sevendays, Days.thirtydays, Days.sixtydays];

interface DayTabProps {
  onChange: (day: Days) => void;
}
export default function DayTab({ onChange }: DayTabProps) {
  const [selectedDay, setSelectedDay] = useState<Days>(Days.sevendays);
  const handleClick = (day: Days) => {
    setSelectedDay(day);
    onChange(day);
  };
  return (
    <div className="loans-overview__metric-section__days-container">
      {days.map((day, index) => (
        <div
          className={`loans-overview__metric-section__days-container__tab ${
            selectedDay === day && 'loans-overview__metric-section__days-container__tab--active'
          }`}
          role="button"
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleClick(day);
            }
          }}
          tabIndex={index}
          key={day}
          onClick={() => handleClick(day)}
        >
          {day}
        </div>
      ))}
    </div>
  );
}
