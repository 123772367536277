import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addMonths } from 'date-fns';

import { LoanDetails } from '../../../shared/models/platform-admin/loans/LoanModel';
import { formatDateForLoan, getPageSettings, getTimePeriod } from '../../../shared/utils';
import { LoanCreationLimitationsDto } from '../dtos/LoanCreationLimitationsDto';
import { LoanStage } from '../dtos/LoanTypes';

import { acceptLoan } from './actions/acceptLoan';
import { closeLoan } from './actions/closeLoan';
import { getLimitations } from './actions/getLimitations';
import { getLoanDetails } from './actions/getLoanDetails';
import { getLoans } from './actions/getLoans';
import { loanShareApi } from './actions/loanShareApi';
import { loanSubmissionApi } from './actions/loanSubmissionApi';
import { scheduleApi } from './actions/scheduleApi';

export enum LoanType {
  NEW_LOAN = 'New Loan',
  PRE_EXISTINS_LOAN = 'Pre-existing loan',
}

export enum LoanBorrowerType {
  EMPLOYEE = 'EMPLOYEE',
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
}

export enum PlanType {
  MONTHLY = 'INSTALLMENTS',
  ONE_TIME = 'ONETIMEPAYMENT',
}

export type TLoanBusinessType = {
  id: number;
  title: string;
  image: string;
};

export type TLoanReasonType = {
  id: number;
  title: string;
  image: string;
};

export type MonetaryValueDto = {
  value: number;
  currency: string;
};

export type PaymentScheduleItemDto = {
  position: number;
  paymentsLeft: number;
  beginningBalance: number;
  endingBalance: number;
  interest: number;
  amount: number;
  currency: string;
  failed: boolean;
  date?: string;
  label?: string;
};

export type PaymentScheduleDetailedDto = {
  payments: PaymentScheduleItemDto[];
  amount: MonetaryValueDto;
  installmentInterestPercentage: number;
  dueDate: string;
  scheduleJwt: string;
  processingFee: null;
  convenienceFee: {
    cost: string;
    description: string;
    displayName: string;
    kind: string;
    value: {
      amount: number;
      kind: string;
    };
  } | null;
};

export interface Loan {
  uuid: string;
  lender: string;
  lenderType: string;
  borrower: {
    uuid: string;
    redactedUsername: string;
    redactedPhoneNum: string;
    redactedEmail: string;
  };
  borrowerType: string;
  createdBy: string;
  createdAt: string;
  targetContactUri: string;
  stage: LoanStage;
  isLendLoan: boolean;
  amount: {
    value: number;
    currency: string;
  };
  repaymentDateFrom: string;
  repaymentDateTo: string;
  firstPaymentDate: string;
  nextPaymentDate: string;
  dueDate: string;
  initialTargetName: string;
  isCashPickupEnabled: boolean;
}

export interface LoanResponse {
  limit: number;
  skip: number;
  total: number;
  loans: Loan[];
}

export interface LoanTableSettings {
  searchText: string;
  loanStatus: string;
  loanStatusConverted: string;
  loanReason: string;
  countRow: string;
  nbrPage: number;
  skip: number;
  sortBy: string;
  sortOrder: 'desc' | 'asc';
  periodTime: string[];
}

type LoansSliceState = {
  loanType: LoanType | string;
  loanBorrowerType: LoanBorrowerType | string;
  firstName: string;
  lastName: string;
  phone: string;
  loanBusinessType?: TLoanBusinessType | undefined;
  loanReasonType?: TLoanBusinessType | undefined;
  loanPaymentType: PlanType | string;
  loanRepaymentDate?: string;
  loanRepaymentMonths: number;
  loanRepaymentMonthsValue: number;
  loanStartDate: string;
  paymentScheduleIsLoading: boolean;
  paymentScheduleDetailed: PaymentScheduleDetailedDto;
  createdLoanIsLoading: boolean;
  createdLoanId: string;
  loanDeeplinkIsLoading: boolean;
  loanDeeplink: string;
  isLoanShareScreenShow: boolean;
  loanDetails?: LoanDetails | undefined;
  closeLoanIsLoading: boolean;
  acceptLoanIsLoading: boolean;
  finishedLoanCreation: boolean;
  loans: LoanResponse;
  loansTableSettings: LoanTableSettings;
  isLimitationsLoading: boolean;
  limitations: LoanCreationLimitationsDto;
};

const initialState: LoansSliceState = {
  loanType: '',
  loanBorrowerType: '',
  firstName: '',
  lastName: '',
  phone: '',
  loanPaymentType: '',
  loanRepaymentDate: '',
  loanRepaymentMonths: 2,
  loanRepaymentMonthsValue: 0,
  loanStartDate: '',
  paymentScheduleIsLoading: false,
  paymentScheduleDetailed: {
    payments: [],
    amount: {
      value: 0,
      currency: 'USD',
    },
    installmentInterestPercentage: 0,
    dueDate: '',
    scheduleJwt: '',
    processingFee: null,
    convenienceFee: null,
  },
  createdLoanIsLoading: false,
  createdLoanId: '',
  loanDeeplinkIsLoading: false,
  loanDeeplink: '',
  isLoanShareScreenShow: false,
  loanDetails: undefined,
  closeLoanIsLoading: false,
  acceptLoanIsLoading: false,
  finishedLoanCreation: false,
  loans: {
    limit: 0,
    skip: 0,
    total: 0,
    loans: [],
  },
  loansTableSettings: {
    searchText: getPageSettings('zaal_loans', 'searchText') || '',
    loanStatus: getPageSettings('zaal_loans', 'loanStatus') || '',
    loanStatusConverted: getPageSettings('zaal_loans', 'loanStatusConverted') || '',
    loanReason: getPageSettings('zaal_loans', 'loanReason') || '',
    countRow: getPageSettings('zaal_loans', 'countRow') || '8',
    nbrPage: getPageSettings('zaal_loans', 'nbrPage') || 1,
    skip: getPageSettings('zaal_loans', 'skip') || 0,
    sortBy: getPageSettings('zaal_loans', 'sortBy') || 'createdAt',
    sortOrder: getPageSettings('zaal_loans', 'sortOrder') || 'desc',
    periodTime: getPageSettings('zaal_loans', 'sortOrder') || getTimePeriod(365),
  },
  isLimitationsLoading: false,
  limitations: {
    bankAccounts: {
      value: 10,
      unlimited: true,
    },
    minLoanAmount: 20,
    maxLoanAmount: 25000,
    loansCount: {
      value: 50,
      unlimited: true,
    },
    serviceFeePercent: 5,
    monthlyPayback: {
      min: 2,
      max: 36,
    },
    onetimePayback: {
      min: 2,
      max: 36,
    },
  },
};

export const loansSlice = createSlice({
  name: 'zaal-loans',
  initialState: initialState as LoansSliceState,
  reducers: {
    setLoanType: (state, action: PayloadAction<LoanType | string>) => {
      state.loanType = action.payload;
    },
    setLoanBorrowerType: (state, action: PayloadAction<LoanBorrowerType | string>) => {
      state.loanBorrowerType = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setLoanBusinessType: (state, action: PayloadAction<TLoanBusinessType | undefined>) => {
      state.loanBusinessType = action.payload;
    },
    setLoanReasonType: (state, action: PayloadAction<TLoanBusinessType | undefined>) => {
      state.loanReasonType = action.payload;
    },
    setLoanPaymentType: (state, action: PayloadAction<PlanType | string>) => {
      state.loanPaymentType = action.payload;
    },
    setLoanRepaymentDate: (state, action: PayloadAction<string>) => {
      state.loanRepaymentDate = action.payload;
    },
    setLoanRepaymentMonths: (state, action: PayloadAction<number>) => {
      state.loanRepaymentMonths = action.payload;
    },
    setLoanRepaymentMonthsValue: (state, action: PayloadAction<number>) => {
      state.loanRepaymentMonthsValue = action.payload;
    },
    setLoanStartDate: (state, action: PayloadAction<string>) => {
      state.loanStartDate = action.payload;
    },
    setCreatedLoanIsLoading: (state, action: PayloadAction<boolean>) => {
      state.createdLoanIsLoading = action.payload;
    },
    setIsLoanShareScreenShow: (state, action: PayloadAction<boolean>) => {
      state.isLoanShareScreenShow = action.payload;
    },
    // table settings
    setTableSearchText: (state, action: PayloadAction<string>) => {
      state.loansTableSettings.searchText = action.payload;
    },
    setTableLoanStatus: (state, action: PayloadAction<string>) => {
      state.loansTableSettings.loanStatus = action.payload;
    },
    setTableLoanStatusConverted: (state, action: PayloadAction<string>) => {
      state.loansTableSettings.loanStatusConverted = action.payload;
    },
    setTableReason: (state, action: PayloadAction<string>) => {
      state.loansTableSettings.loanReason = action.payload;
    },
    setTableLimit: (state, action: PayloadAction<string>) => {
      state.loansTableSettings.countRow = action.payload;
    },
    setTableNbrPage: (state, action: PayloadAction<number>) => {
      state.loansTableSettings.nbrPage = action.payload;
    },
    setTableSkip: (state, action: PayloadAction<number>) => {
      state.loansTableSettings.skip = action.payload;
    },
    setTableSortField: (state, action: PayloadAction<string>) => {
      state.loansTableSettings.sortBy = action.payload;
    },
    setTableSortOrder: (state, action: PayloadAction<'desc' | 'asc'>) => {
      state.loansTableSettings.sortOrder = action.payload;
    },
    setTablePeriodTime: (state, action: PayloadAction<string[]>) => {
      state.loansTableSettings.periodTime = action.payload;
    },
    resetLoanStates: (state) => {
      state.loanType = '';
      state.loanBorrowerType = '';
      state.firstName = '';
      state.phone = '';
      state.loanBusinessType = undefined;
      state.loanReasonType = undefined;
      state.loanPaymentType = '';
      state.loanRepaymentDate = '';
      state.loanRepaymentMonths = 2;
      state.loanRepaymentMonthsValue = 0;
      state.loanStartDate = '';
      state.paymentScheduleIsLoading = false;
      state.paymentScheduleDetailed = {
        payments: [],
        amount: {
          value: 0,
          currency: 'USD',
        },
        installmentInterestPercentage: 0,
        dueDate: '',
        scheduleJwt: '',
        processingFee: null,
        convenienceFee: null,
      };
      state.createdLoanIsLoading = false;
      state.createdLoanId = '';
      state.loanDeeplinkIsLoading = false;
      state.loanDeeplink = '';
      state.isLimitationsLoading = false;
      state.limitations = {
        bankAccounts: {
          value: 10,
          unlimited: true,
        },
        minLoanAmount: 20,
        maxLoanAmount: 25000,
        loansCount: {
          value: 50,
          unlimited: true,
        },
        serviceFeePercent: 5,
        monthlyPayback: {
          min: 2,
          max: 36,
        },
        onetimePayback: {
          min: 2,
          max: 36,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(scheduleApi.pending, (state) => {
        state.paymentScheduleIsLoading = true;
      })
      .addCase(scheduleApi.fulfilled, (state, action) => {
        state.paymentScheduleIsLoading = false;
        const startDate = new Date(state.loanStartDate);

        state.paymentScheduleDetailed.payments = action.payload.payments.map((payment, index) => {
          const paymentDate = addMonths(startDate, payment.position - 1);
          const paymentLabel = `Payment ${payment.position}/${action.payload.payments.length}`;

          return {
            ...payment,
            date: formatDateForLoan(paymentDate),
            label: paymentLabel,
          };
        });

        state.paymentScheduleDetailed = {
          ...state.paymentScheduleDetailed,
          ...action.payload,
          payments: state.paymentScheduleDetailed.payments,
        };
      })
      .addCase(scheduleApi.rejected, (state) => {
        state.paymentScheduleIsLoading = false;
      })
      .addCase(loanSubmissionApi.pending, (state) => {
        state.createdLoanIsLoading = true;
        state.isLoanShareScreenShow = true;
      })
      .addCase(loanSubmissionApi.fulfilled, (state, action) => {
        const { uuid } = action.payload;
        state.createdLoanId = uuid;
        state.createdLoanIsLoading = false;
      })
      .addCase(loanSubmissionApi.rejected, (state) => {
        state.createdLoanIsLoading = false;
        state.isLoanShareScreenShow = true;
      })
      .addCase(loanShareApi.pending, (state) => {
        state.loanDeeplinkIsLoading = true;
      })
      .addCase(loanShareApi.fulfilled, (state, action) => {
        state.loanDeeplink = action.payload;
        state.loanDeeplinkIsLoading = false;
      })
      .addCase(loanShareApi.rejected, (state) => {
        state.loanDeeplinkIsLoading = false;
      })
      .addCase(getLoans.fulfilled, (state, action) => {
        state.loans = action.payload;
      })
      .addCase(getLoanDetails.fulfilled, (state, action) => {
        state.loanDetails = action.payload;
      })
      .addCase(closeLoan.pending, (state) => {
        state.closeLoanIsLoading = true;
      })
      .addCase(closeLoan.fulfilled, (state) => {
        state.closeLoanIsLoading = false;
      })
      .addCase(closeLoan.rejected, (state) => {
        state.closeLoanIsLoading = false;
      })
      .addCase(acceptLoan.pending, (state) => {
        state.acceptLoanIsLoading = true;
      })
      .addCase(acceptLoan.fulfilled, (state) => {
        state.acceptLoanIsLoading = false;
      })
      .addCase(acceptLoan.rejected, (state) => {
        state.acceptLoanIsLoading = false;
      })
      .addCase(getLimitations.pending, (state) => {
        state.isLimitationsLoading = true;
      })
      .addCase(getLimitations.fulfilled, (state, action) => {
        state.limitations = action.payload;
        state.isLimitationsLoading = false;
      })
      .addCase(getLimitations.rejected, (state) => {
        state.isLimitationsLoading = false;
      });
  },
});

export default loansSlice;
export const { reducer: loansReducer } = loansSlice;
export const {
  setLoanType,
  setLoanBorrowerType,
  setPhone,
  setLoanBusinessType,
  setLoanReasonType,
  setLoanPaymentType,
  setLoanRepaymentDate,
  setLoanRepaymentMonths,
  setLoanRepaymentMonthsValue,
  setLoanStartDate,
  setCreatedLoanIsLoading,
  setIsLoanShareScreenShow,
  setTableSearchText,
  setTableLoanStatus,
  setTableLoanStatusConverted,
  setTableReason,
  setTableLimit,
  setTableNbrPage,
  setTableSkip,
  setTableSortField,
  setTableSortOrder,
  setTablePeriodTime,
  resetLoanStates,
} = loansSlice.actions;
