import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { addDays } from 'date-fns';

import {
  DateRangeSettings,
  DateRangeTimeUnits,
  DateRangeTuple,
  DateRangeType,
  RefundInfo,
} from '../../../shared';
import StoreKeys from '../../../shared/constants/AsyncStoreKeys';
import { BillPayment, BillPaymentsSettings } from '../../../shared/models';
import { getPageSettings } from '../../../shared/utils';

import { getAllBillPayments, getBillPayments, getSubmittedByName } from './actions';

const initialBillPaymentsSettings: BillPaymentsSettings[] = [
  { id: 'customerName', name: 'Customer name', checked: true, disabled: true, fixed: true },
  { id: 'accountNumber', name: 'Account number', checked: true, disabled: true },
  { id: 'dateOfBirth', name: 'Date of birth', checked: true },
  { id: 'address', name: 'Address', checked: true },
  { id: 'phoneNumber', name: 'Phone number', checked: true },
  { id: 'email', name: 'Email', checked: true },
  { id: 'requestApproved', name: 'Request approved', checked: true, sortName: 'initiatedAt' },
  { id: 'transactionId', name: 'Transaction Id', checked: true },
  {
    id: 'paymentAmount',
    name: 'Payment amount',
    checked: true,
    disabled: true,
    sortName: 'amount.value',
  },
  { id: 'paymentStatus', name: 'Payment status', checked: true, disabled: true },
  { id: 'paymentDate', name: 'Payment date', checked: true, sortName: 'paymentAt' },
  { id: 'view', name: 'View', checked: true },
];

type PaymentsSliceState = {
  billPayments: Array<BillPayment>;
  allBillPayments?: Array<BillPayment>;
  billPaymentsSettings: Array<BillPaymentsSettings>;
  totalBillPayments: number;
  allTotalBillPayments?: number;
  modalRefundOpen: boolean;
  modalRefundedOpen: boolean;
  refundInfo?: RefundInfo;
  selectedBillPayment?: BillPayment;
  selectedRefundIndex?: number;
  selectedSubmittedByName?: string;
  dateRangeFilter: DateRangeTuple;
  dateRangeSettings: DateRangeSettings;
};

const initialState: PaymentsSliceState = {
  billPayments: [],
  billPaymentsSettings: [],
  totalBillPayments: 0,
  modalRefundOpen: false,
  modalRefundedOpen: false,
  dateRangeFilter: [
    getPageSettings('billPayments', 'dateStart') || addDays(new Date(), -7).toISOString(),
    getPageSettings('billPayments', 'dateEnd') || new Date().toISOString(),
  ],
  dateRangeSettings: getPageSettings('billPayments', 'dateRangeSettings') || {
    type: DateRangeType.relative,
    timeUnits: DateRangeTimeUnits.days,
    value: 7,
  },
};

export const paymentsSlice = createSlice<
  PaymentsSliceState,
  SliceCaseReducers<PaymentsSliceState>,
  'payments'
>({
  name: 'payments',
  initialState,
  reducers: {
    setModalRefundOpen: (state, action: PayloadAction<{ open: boolean; rInfo?: RefundInfo }>) => {
      state.modalRefundOpen = action.payload.open;
      state.refundInfo = action.payload.rInfo;
    },
    setModalRefundedOpen: (state, action: PayloadAction<{ open: boolean; rInfo?: RefundInfo }>) => {
      state.modalRefundedOpen = action.payload.open;
      state.refundInfo = action.payload.rInfo;
    },
    getBillPaymentsSettings: (state) => {
      const settings = localStorage.getItem(StoreKeys.paymentsSettings);

      if (!settings) {
        state.billPaymentsSettings = initialBillPaymentsSettings;
        localStorage.setItem(
          StoreKeys.paymentsSettings,
          JSON.stringify(initialBillPaymentsSettings),
        );
      } else {
        const storage = JSON.parse(settings);
        const newSettings = initialBillPaymentsSettings.map((el) => ({
          ...el,
          checked: storage.find((i: BillPaymentsSettings) => i.id === el.id)?.checked ?? el.checked,
        }));
        state.billPaymentsSettings = newSettings;
      }
    },
    changeBillPaymentsSettings: (state, action) => {
      const { id } = action.payload;
      const current = state.billPaymentsSettings.find((el) => el.id === id);
      current!.checked = !current!.checked;

      localStorage.setItem(StoreKeys.paymentsSettings, JSON.stringify(state.billPaymentsSettings));
    },
    disableBillPaymentsSettings: (state, action) => {
      const { id, isEnable } = action.payload;
      const current = state.billPaymentsSettings.find((el) => el.id === id);
      if (current) {
        current!.disabled = !isEnable;
        localStorage.setItem(
          StoreKeys.paymentsSettings,
          JSON.stringify(state.billPaymentsSettings),
        );
      }
    },
    resetBillPaymentsSettings: (state) => {
      state.billPaymentsSettings = initialBillPaymentsSettings;
      localStorage.setItem(StoreKeys.paymentsSettings, JSON.stringify(initialBillPaymentsSettings));
    },
    setSelectedBillPayment: (state, action) => {
      state.selectedBillPayment = action.payload;
    },
    deleteSelectedBillPayment: (state) => {
      delete state.selectedBillPayment;
      delete state.selectedRefundIndex;
      delete state.selectedSubmittedByName;
    },
    setRefundEventIndex: (state, action) => {
      state.selectedRefundIndex = action.payload;
    },

    setTablePaymentsDateFilter: (state, action: PayloadAction<DateRangeTuple>) => {
      state.dateRangeFilter = action.payload;
    },

    setTablePaymentsDateRangeSettings: (state, action: PayloadAction<DateRangeSettings>) => {
      state.dateRangeSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBillPayments.fulfilled, (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    });
    builder.addCase(getAllBillPayments.fulfilled, (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    });
    builder.addCase(getSubmittedByName.fulfilled, (state, action) => {
      state.selectedSubmittedByName = action.payload;
    });
  },
});

export default paymentsSlice;
export const { reducer: paymentsReducer } = paymentsSlice;
export const {
  setModalRefundOpen,
  setModalRefundedOpen,
  getBillPaymentsSettings,
  changeBillPaymentsSettings,
  disableBillPaymentsSettings,
  resetBillPaymentsSettings,
  setSelectedBillPayment,
  deleteSelectedBillPayment,
  setRefundEventIndex,
  setTablePaymentsDateFilter,
  setTablePaymentsDateRangeSettings,
} = paymentsSlice.actions;
